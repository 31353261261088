import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for AttachmentLayout Styles
 * 
 * This hook defines the styles used by the AttachmentLayout component.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { sm, section } = Dimens.spacing;

  const styles = {
    attachmentLayout: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1200,
      bgcolor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& #react-doc-viewer': {
        overflowY: 'auto',
        background: theme.palette.background.default,
        bgcolor: theme.palette.background.default,
        '& #proxy-renderer': {
          display: 'flex',
          flex: 1
        },
        '& #pdf-controls': {
          zIndex: 3,
          bgcolor: 'transparent',
          '& #pdf-pagination-info': {
            ...theme.typography.p1,
            color: theme.palette.text.primary
          },
          '& #pdf-download': {
            display: 'none'
          }
        }
      }
    },
    viewheader: {
      ...commonStyles.borderBottom,
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        p: 0,
        'svg': {
          ...commonStyles.smIcon
        }
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    editHeader: {
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        p: 0
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    titleLayout: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& p': {
        ...commonStyles.textEllipsis(1)
      },
      '& span': {
        ...commonStyles.textEllipsis(1),
        color: theme.palette.text.secondary
      }
    },
    videoView: {
      maxWidth: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: 'auto',
      overflow: 'auto',
      flex: 1,
      bgcolor: theme.palette.background.default
    },
    imageView: {
      height: 'auto',
      width: 'auto',
      objectFit: 'contain',
      maxHeight: '100%',
      overflow: 'auto',
      flex: 1,
      p: sm,
      bgcolor: theme.palette.background.default
    }
  };

  return styles;
};

export default useStyles;