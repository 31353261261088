import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for AddNewPermissionDialog styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const { base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    ...commonStyles,
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogSmallWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('sm')]: {
          p: lg,
          minWidth: '100%'
        }
      },
      '& .MuiDialogContent-root': {
        ...commonStyles.noScrollBar,
        p: 0,
        maxHeight: Dimens.dialogContentMaxHeight,
        overflowY: 'auto'
      },
      '& .MuiDialogActions-root': {
        p: 0,
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiButton-colorPrimary': {
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            margin: 0
          }
        }
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column'
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'end',
      width: '100%'
    }
  };

  return styles;
};

export default useStyles;
