/* eslint-disable */

import React, { createContext, useReducer } from 'react';
import deptReducer, { deptInitialState } from '../data/department/reducer';
import deviceReducer, { deviceInitialState } from '../data/device/reducers';
import directoryReducer, { directoryInitialState } from '../data/directory/reducers';
import domainReducer, { domainInitialState } from '../data/domain/reducers';
import inboxReducer, { inboxInitialState } from '../data/inbox/reducer';
import profileReducer, { profileInitialState } from '../data/profile/reducers';
import siteReducer, { siteInitialState } from '../data/site/reducers';
import userReducer, { userInitialState } from '../data/user/reducers';
import userTypeReducer, { userTypeInitialState } from '../data/user-type/reducer';

import AppState from '../types/states/app-state';
import useCombinedReducers from 'use-combined-reducers';

interface IContextProps {
  state: AppState;
  dispatch: (action: any) => void;
}

export const Store = createContext({} as IContextProps);

/**
 * This file defines the application global state management using React Context API.
 * 
 * Components can access and update the state using the provided context and reducer.
 */
export const StoreProvider = (props: any) => {
  const [ state, dispatch ] = useCombinedReducers<any, any>({
    inbox: useReducer(inboxReducer, inboxInitialState),
    site: useReducer(siteReducer, siteInitialState),
    department: useReducer(deptReducer, deptInitialState),
    directory: useReducer(directoryReducer, directoryInitialState),
    user: useReducer(userReducer, userInitialState),
    userType: useReducer(userTypeReducer, userTypeInitialState),
    domain: useReducer(domainReducer, domainInitialState),
    profile: useReducer(profileReducer, profileInitialState),
    device: useReducer(deviceReducer, deviceInitialState)
  });
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
};
