import {
	API_FAILURE,
	APP_FEATURE_LIST_SUCCESS,
	CREATE_USER_TYPE,
	DELETE_USER_TYPE,
	FETCH_APP_FEATURE_LIST,
	FETCH_USER_TYPE_LIST,
	RESET_USER_TYPE_STATE,
	UPDATE_USER_TYPE,
	USER_TYPE_LIST_SUCCESS,
	UserTypeActionTypes,
} from './action-types';

import UserTypeState from '../../types/states/user-type-state';

export const userTypeInitialState: UserTypeState = {
	apiStatus: undefined,
	userTypeList: [],
	appFeatureList: []
};

/**
 * UserType reducer
 *
 * Handles state updates for user type-related data, including fetching, success, and failure states.
 */
export const userTypeReducer = (state: UserTypeState, action: UserTypeActionTypes): UserTypeState => {
	switch (action.type) {
		case FETCH_USER_TYPE_LIST:
		case FETCH_APP_FEATURE_LIST:
		case CREATE_USER_TYPE:
		case UPDATE_USER_TYPE:
		case DELETE_USER_TYPE:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case USER_TYPE_LIST_SUCCESS:
			return {
				...state,
				userTypeList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case APP_FEATURE_LIST_SUCCESS:
			return {
				...state,
				appFeatureList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_USER_TYPE_STATE:
			return {
				...userTypeInitialState
			};
		default:
			return state;
	}
};

export default userTypeReducer;
