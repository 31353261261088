import { Box, Dialog, DialogActions, DialogContent, DialogProps, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import InlineAlert from '../../../components/InlineAlert';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import { UPDATE_PROFILE } from '../../../data/profile/action-types';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { deptSchema } from '../../../utils/validation-schema';
import theme from '../../../theme/theme';
import { useDepartmentApi } from '../../../data/department/api';
import { useProfileApi } from '../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface for props passed to the EditProfileDialog component.
 */
interface Props extends DialogProps {
	user: User;
	onClose: () => void;
}

/**
 * React component representing a dialog for edit Profile.
 *
 * @param {Props} props Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const EditProfile: React.FC<Props> = (props: Props) => {

	const { user, onClose, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [ showSaveDialog, setShowSaveDialog ] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<User>>();
	const deptApi = useDepartmentApi();
	const profileApi = useProfileApi();
	const apiStatus = deptApi.state.department.apiStatus;
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	/**
	 * Handles closing the dialog, with confirmation if the form is dirty.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
	 * Handles form submission by calling updateProfileDetail API and updating local state.
	 *
	 * @param {User} values - User data from the submitted form.
	 */
	const onSubmit = (values: User) => {
		profileApi.updateProfileDetail(values).then((data) => {
			if (data) {
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{t('editProfile')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={deptSchema}
				initialValues={user}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent sx={styles.content}>
							<Box sx={styles.formInnerWrapper}>
								<InlineAlert message={Util.getApiError([ UPDATE_PROFILE ], apiStatus)} />
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Field
											name="name"
											label={t('name')}
											placeholder={t('name')}
											component={CustomInput}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											name="position"
											label={t('position')}
											placeholder={t('position')}
											component={CustomInput}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="about"
											label={t('aboutYou')}
											placeholder={t('aboutYouPlaceholder')}
											multiline
											component={CustomInput}
										/>
										<Field
											name='departments'
											label={t('departments')}
											placeholder={t('findDepartments')}
											component={CustomAutocomplete}
											noOptionsText={t('noDepts')}
											multiple
											defaultOptionId={user.defaultDepartmentId}
											menu={deptApi.state.department.departmentList}
										/>
									</Grid>
								</Grid>
							</Box>
						</DialogContent>
						<DialogActions>
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={t('save')}
								color='primary'
								fullWidth={isMobile}
								disabled={!dirty || !isValid}
								startIcon={<SaveIcon sx={styles.smIcon} />}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([ UPDATE_PROFILE ], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onClose}
				onClose={() => setShowSaveDialog(false)}
			/>
		</Dialog>
	);
};

export default EditProfile;