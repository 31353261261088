import React, { useCallback, useContext } from 'react';
import {
	apiFailure,
	deptListSuccess,
	fetchDeptList,
	initDeptCreate,
	initDeptDelete,
	initDeptDetailFetch,
	initDeptUpdate,
	resetDeptState
} from './actions';
import { doDelete, doGet, doPatch, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import Department from '../../types/department';
import DepartmentDetailResponse from '../../types/department-detail-response';
import DepartmentListResponse from '../../types/dept-list-response';
import { HttpStatusCode } from 'axios';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import { Store } from '../../store/store';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to manage department list data and actions.
 *
 * @returns An object containing functions for fetching department list and the department list state.
 */
export function useDepartmentApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);

	/**
   * Fetches the department list from the API and dispatches actions based on the result.
   */
	const fetchDepartmentList = useCallback(async () => {
		dispatch(fetchDeptList());
		try {
			const response: DepartmentListResponse = await doGet(APIConfig.departmentSearch);
			dispatch(deptListSuccess(response.data || []));
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Creates a new department by sending a POST request to the API.
   * 
   * @param {Department} department - The department object to be created.
	 * 
   * @returns {DepartmentDetailResponse} The response object containing the created department details.
   */
	const createDepartment = useCallback(async (department: Department) => {
		dispatch(initDeptCreate());
		try {
			const response: DepartmentDetailResponse = await doPost(APIConfig.department, department);
			response.data.isFetched = true;
			const deptList = [...state.department.departmentList];
			deptList.push(response.data);
			dispatch(deptListSuccess(deptList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Updates an existing department by sending a POST request to the API.
   * 
   * @param {Department} department - The department object with updated details.
	 * 
   * @returns {DepartmentDetailResponse} The response object containing the updated department details.
   */
	const updateDepartment = useCallback(async (department: Department) => {
		dispatch(initDeptUpdate());
		try {
			const response: DepartmentDetailResponse = await doPatch(`${APIConfig.department}/${department.id}`, department);
			response.data.isFetched = true;
			const deptList = [...state.department.departmentList.filter(dept => dept.id !== department.id), response.data];
			dispatch(deptListSuccess(deptList));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Fetches details for a specific department by sending a GET request to the API.
   * 
   * @param {string} id - The ID of the department to fetch details for.
	 * 
   * @returns {DepartmentDetailResponse} The response object containing the department details.
   */
	const fetchDeptDetail = useCallback(async (id: string) => {
		dispatch(initDeptDetailFetch());
		try {
			const response: DepartmentDetailResponse = await doGet(`${APIConfig.department}/${id}`);
			response.data.isFetched = true;
			const deptList = [...state.department.departmentList.filter(dept => dept.id !== id), response.data];
			dispatch(deptListSuccess(deptList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.department.departmentList]);

	/**
   * Deletes a department by sending a DELETE request to the API.
   *
   * @param {Department} dept - The department object to be deleted.
   * @returns {Department} (optional) The deleted department object on success.
   */
	const deleteDepartment = useCallback(async (dept: Department) => {
		dispatch(initDeptDelete());
		try {
			await doDelete(`${APIConfig.department}/${dept.id}`);
			const deptList = [...state.department.departmentList.filter(item => item.id !== dept.id)];
			dispatch(deptListSuccess(deptList));
			return dept;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Resets the site state in the Redux store.
	 */
	const resetDept = () => {
		dispatch(resetDeptState());
	}

	/**
   * Dispatches an API failure action with an error message.
   *
   * @param {any} error - The error object.
   */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
    dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
  }

	return {
		fetchDepartmentList,
		createDepartment,
		updateDepartment,
		fetchDeptDetail,
		deleteDepartment,
		resetDept,
		state
	};
}
