import Dimens from '../../../../theme/dimens';
import theme from '../../../../theme/theme';

/**
 * useStyles custom hook generates MUI theme dependent styles for the LanguageSubMenu component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const { lg, section } = Dimens.spacing;

	const styles = {
		container: {
			p: `0 ${section} ${section} ${section}`,
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		}
	};

	return styles;
};

export default useStyles;
