import { Field, Form, Formik } from 'formik';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EyeIcon from '../../components/CustomIcons/EyeIcon';
import EyeOffIcon from '../../components/CustomIcons/EyeOffIcon';
import PasswordRequest from '../../types/password-request';
import PwdImageDark from '../../assets/sign_in_password_dark.svg';
import PwdImageLight from '../../assets/sign_in_password_light.svg';
import SignUpLayout from '../../layouts/SignUpLayout';
import SignUpPayload from '../../types/ui/sign-up-payload';
import ThemeUtil from '../../theme/theme-util';
import Util from '../../utils/util';
import ValidationItem from '../../types/ui/validation-item';
import { registeredPwdSchema } from '../../utils/validation-schema';
import { useDisableBackNav } from '../../hooks/use-disable-back-nav';
import { useSignUpApi } from '../../data/sign-up/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * RegisterPasswordScreen component
 *
 * This component handles the password creation step of the sign-up process.
 * It uses Formik for form management and validation, and MUI components for input and button elements.
 * 
 * Key functionalities:
 *  - Displays a password input field with password visibility toggle.
 *  - Validates password strength using `Util.getPasswordValidationStatus`.
 *  - Submits password data to the API using `signUpApi.registerPassword`.
 *  - Handles form submission and input changes.
 *  - Renders the password input field and submit button within a SignUpLayout.
 *
 * @returns JSX.Element - The rendered sign-up password screen component.
 */
const RegisterPasswordScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signUpApi = useSignUpApi();
  useDisableBackNav();
  const challengePayload: SignUpPayload = location.state;
  const initialValues: PasswordRequest = {
    newPassword: ''
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validationList, setValidationList] = useState<Array<ValidationItem>>(Util.getPasswordValidationStatus(''));

  /**
   * Navigates to welcome page if the challenge payload is invalid (Unauthorized access).
   */
  useEffect(() => {
    if (!challengePayload?.isValidSignUpRequest) {
      navigate(APP_NAV.WELCOME);
    }
  }, []);

  /**
   * Handles form submission.
   * 
   * @param {PasswordRequest} value - Form values.
   */
  const onSubmit = (value: PasswordRequest): void => {
    signUpApi.registerPassword({ ...value });
  }

  /**
   * Handles input text changes and updates the validation list.
   * 
   * @param {string} value - Input value.
   */
  const onChangeText = (value: string) => {

    setValidationList(Util.getPasswordValidationStatus(value));
  }

  return (
    <SignUpLayout
      error={signUpApi.state.error}
      showProgressBar={signUpApi.state.isLoading}
      headerTitle={t('createPassword')}
      image={isLightTheme ? PwdImageLight : PwdImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={registeredPwdSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid, values }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Field
              name='newPassword'
              label={t('password')}
              placeholder={t('password')}
              component={CustomInput}
              onChangeText={onChangeText}
              validationList={validationList}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                values.newPassword &&
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                    {showPassword ? <EyeOffIcon sx={styles.icon} /> : <EyeIcon sx={styles.icon} />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <CustomButton type='submit' title={t('next')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignUpLayout>
  );
};

export default RegisterPasswordScreen;
