import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import InlineAlert from '../../../../components/InlineAlert';
import { Pager } from '../../../../types/pager';
import { UPDATE_PAGER } from '../../../../data/device/action-types';
import Util from '../../../../utils/util';
import { deptSchema } from '../../../../utils/validation-schema';
import { useDeviceApi } from '../../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  pager: Pager;
}

/**
 * Renders a form for editing pager details.
 *
 * @param {Props} props - Component props containing the pager data.
 * 
 * @returns JSX element representing the pager details tab.
 */
const DetailsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [pager, setPager] = useState<Pager>(props.pager);
  const deviceApi = useDeviceApi();
  const apiStatus = deviceApi.state.device.apiStatus;

  /**
   * Handles form submission, updates the pager details.
   *
   * @param {Pager} values - The updated pager data from the form.
   */
  const onSubmit = (values: Pager) => {
    deviceApi.updatePager(values).then((data) => {
      if (data) {
        setPager(values);
      }
    });
  }

  return (
    <Grid sx={styles.wrapper}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={deptSchema}
        initialValues={pager}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box sx={styles.formInnerWrapper}>
              <InlineAlert message={Util.getApiError([UPDATE_PAGER], apiStatus)} />
              <Field
                name='name'
                label={t('name')}
                placeholder={t('name')}
                component={CustomInput}
              />
              <Field
                name='pagerType'
                label={t('type')}
                placeholder={t('select')}
                component={CustomAutocomplete}
                noOptionsText={t('noPagerTypes')}
                menu={deviceApi.state.device.pagerTypeList}
                showDropdownIcon
                readOnly
              />
              <Field
                name='destination'
                label={t('identifier')}
                placeholder={t('ricNumber')}
                type='number'
                component={CustomInput}
                readOnly
              />
            </Box>
            <CustomButton
              type='submit'
              title={t('save')}
              color='primary'
              disabled={!dirty || !isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <CircularProgressBar show={Util.isApiLoading([UPDATE_PAGER], apiStatus)} />
    </Grid>
  );
};

export default DetailsTab;