import { ACCEPTED_IMAGE_EXTENSIONS, TAB_USER_PROFILE, TAB_USER_ROLES } from '../../../utils/constants';
import { Avatar, Badge, Box, IconButton, Tab, Typography, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import CameraIcon from '../../../components/CustomIcons/CameraIcon';
import MenuData from '../../../types/ui/menu-data';
import { PROFILE_EDIT_TAB_LIST } from '../../../utils/ui-constants';
import ProfileDetails from './ProfileDetails';
import ProfileRoles from './ProfileRoles';
import ProfileStatus from './ProfileStatus';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import theme from '../../../theme/theme';
import { useProfileApi } from '../../../data/profile/api';
import useStyles from './styles';

interface Props {
	user: User;
	status: MenuData;
	onStatusChange: (item: MenuData) => void;
}
/**
 * ViewProfile Component
 *
 * This component is responsible for displaying the user's profile details and roles.
 * It adjusts the layout based on the device's screen size, showing either a side-by-side layout
 * for larger screens or a tabbed layout for smaller devices like tablets or mobiles.
 * It also allows the user to update their profile image.
 * 
 * The component also allows the user to change their profile status and switch between different tabs.
 *
 * @component.
 */
const ViewProfile: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { user, status, onStatusChange } = props;
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const [ selectedTab, setSelectedTab ] = useState(TAB_USER_PROFILE);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const profileApi = useProfileApi();
	
	/**
	 * Determines the content to display based on the selected tab.
	 * 
	 * @function
	 * @param {string} tabId - The ID of the selected tab.
	 * @param {User} userDetail - The user's details to display in the tab content.
	 * 
	 * @returns {JSX.Element} The content component for the selected tab.
	 */
	const getTabPanel = (tabId: string, userDetail: User) => {
		switch (tabId) {
			case TAB_USER_PROFILE:
				return <ProfileDetails userDetail={userDetail} />;
			case TAB_USER_ROLES:
				return <ProfileRoles />;
		}
	};

	/**
	 * handleCameraClick
	 *
	 * Triggers the file input dialog when the camera icon is clicked. 
	 * It simulates a click on the hidden file input field to allow the user to select a new profile image.
	 *
	 * @function
	 * @returns {void}
	 */
	const handleCameraClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	/**
	 * handleFileChange
	 *
	 * Handles the file input change event, which is triggered when a user selects a new image file.
	 * It reads the selected image file, converts it to a base64 string using `FileReader`, and updates
	 * the state with the newly selected image. The function also uploads the image to the API by
	 * calling the `updateProfileImage` function from `profileApi`.
	 *
	 * @function
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The file input change event containing the selected image file.
	 * @returns {void}
	 */
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string); 
      };
      reader.readAsDataURL(file); 
			profileApi.updateProfileImage(file);
    }
  };

	return (
		<Box sx={styles.container}>
			<Box sx={styles.userHeader}>
				<Badge
					sx={styles.cameraBadge}
					overlap="circular"
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					badgeContent={
						<IconButton onClick={handleCameraClick} sx={styles.cameraButton} aria-label="upload picture">
							<CameraIcon sx={styles.cameraIcon} />
						</IconButton>
					}
				>
					<Avatar sx={styles.avatar} src={selectedImage || user.image?.thumbnail || undefined}>
            {(!selectedImage && !user.image?.thumbnail) &&  (
              <Typography variant="h1">
                {Util.getInitialsFromName(user.name)}
              </Typography>
            )}
          </Avatar>
				</Badge>
				{/* Hidden file input */}
				<input
					type="file"
					accept={ACCEPTED_IMAGE_EXTENSIONS}
					ref={fileInputRef}
					style={styles.hiddenInput}
					onChange={handleFileChange}
				/>
				<Box sx={styles.userInfo}>
					<Typography variant='h2' sx={styles.title}>{user.name}</Typography>
					<Typography variant='subheading' sx={styles.subTitle}>{user.email}</Typography>
					<Typography variant='subheading' sx={styles.subTitle}>{user.position}</Typography>
					{!isMobile &&
						<ProfileStatus initialStatus={status} onStatusChange={(menu) => onStatusChange(menu)} />
					}
				</Box>
			</Box>
			{/* Profile Info: Conditional layout */}
			{!isTablet ? (
				// Large screen: show profile and roles side by side
				<Box sx={styles.profileInfo}>
					<ProfileDetails userDetail={user} />
					{/* Profile roles hidden <ProfileRoles /> */}
				</Box>
			) : (
				// Small/medium screen: show tabs
				<TabContext value={selectedTab} >
					{/*Hide the tabs as Roles UI is not needed just yet. */}
					{/* <TabList onChange={(_, newValue) => setSelectedTab(newValue)} aria-label="profile roles tabs" sx={styles.tabList}>
						{
							PROFILE_EDIT_TAB_LIST.map((item, index) => (
								<Tab key={`profile_edit_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList> */}
					{
						PROFILE_EDIT_TAB_LIST.map((item, index) => (
							<TabPanel key={`profile_edit_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
								{getTabPanel(item.id, user)}
							</TabPanel>
						))
					}
				</TabContext>
			)}
		</Box>
	);
};

export default ViewProfile;
