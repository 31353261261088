import CarouselItem, { CarouselProps } from './CarouselItem';
import { Grid, Stack, Typography } from '@mui/material';

import APP_NAV from '../../routes/app-nav';
import AppInfo from '../../../package.json';
import Carousel from '../../components/Carousel';
import CustomButton from '../../components/CustomButton';
import LanguageSelector from '../../components/LanguageSelector';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Welcome Screen Component
 *
 * This component renders the welcome screen for the application.
 * It displays a carousel with introductory information, language selection,
 * and sign-up/sign-in buttons.
 *
 * @returns {JSX.Element} - The rendered JSX element for the welcome screen.
 */
const WelcomeScreen: React.FC = () => {

  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // / Array of walkthrough items for the carousel
  const walkthroughList: Array<CarouselProps> = [{
    title: t('welcome1Title'),
    desc: t('welcome1Desc'),
    image: styles.welcome1
  },
  {
    title: t('welcome2Title'),
    desc: t('welcome2Desc'),
    image: styles.welcome2
  },
  {
    title: t('welcome3Title'),
    desc: t('welcome3Desc'),
    image: styles.welcome3
  }];

  /**
   * Handles the click event for the sign-in button.
   */
  const onSignInClick = () => {
    navigate(APP_NAV.SIGN_IN);
  }

  return (
    <Grid container sx={styles.wrapper}>
      <Stack sx={styles.langWrapper}>
        <LanguageSelector />
      </Stack>
      <Stack sx={styles.contentWrapper}>
        <Carousel>
          {walkthroughList.map((item, index) => (
            <CarouselItem key={`${item}-${index}`} {...item} />
          ))}
        </Carousel>
        <Stack sx={styles.contentBtnWrapper}>
          {/* <CustomButton title={t('signUp')} />
          <Box sx={styles.spacer} /> */}
          <CustomButton title={t('signIn')} color='primary' onClick={onSignInClick} />
        </Stack>
      </Stack>
      <Stack sx={styles.versionWrapper}>
        <Typography variant='footer'>{`${t('version')} ${AppInfo.version} (${AppInfo.versionNo})`}</Typography>
      </Stack>
    </Grid>
  );
};

export default WelcomeScreen;
