import { Box, Tab } from '@mui/material';
import { TAB_DEVICE_EDIT_ASSIGN, TAB_DEVICE_EDIT_DETAIL } from '../../../utils/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AssignTab from './AssignTab';
import { DEVICE_EDIT_TAB_LIST } from '../../../utils/ui-constants';
import DetailsTab from './DetailsTab';
import { Pager } from '../../../types/pager';
import React from 'react';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import { useDeviceApi } from '../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	pager?: Pager;
	onClose: (action?: string) => void;
}

/**
 * Renders a drawer with tabs for editing pager and assign/reassign user.
 *
 * @param {Props} props - Component props containing open state, pager data, and close callback.
 * 
 * @returns JSX element representing the pager edit panel.
 */
const DeviceEditPanel: React.FC<Props> = (props: Props) => {

	const { open, pager, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = React.useState(TAB_DEVICE_EDIT_DETAIL);
	const deviceApi = useDeviceApi();

	/**
	 * Fetches pager details if not already available or incomplete.
	 *
	 * @returns {Pager | null} The fetched pager details or null if not found or loading.
	 */
	const getPagerDetail = () => {
		const detail = deviceApi.state.device.pagerList.find(item => item.id === pager?.id);

		return detail;
	}

	const pagerDetail = getPagerDetail();

	/**
	 * Renders the appropriate TabPanel content based on the selected tab ID.
	 *
	 * @param {string} tabId - ID of the selected tab.
	 * 
	 * @returns {JSX.Element} The content for the TabPanel.
	 */
	const getTabPanel = (tabId: string) => {
		if (pagerDetail) {
			switch (tabId) {
				case TAB_DEVICE_EDIT_DETAIL:
					return <DetailsTab pager={pagerDetail} />;
				case TAB_DEVICE_EDIT_ASSIGN:
					return <AssignTab pager={pagerDetail} />;
			}
		}
	}

	/**
	 * Handles closing the drawer, reseting the device error state in store and reseting tab selection.
	 */
	const onDrawerClose = () => {
		deviceApi.resetError();
		setSelectedTab(TAB_DEVICE_EDIT_DETAIL);
		onClose();
	}

	return (
		<RightDrawerLayout open={open} title={t('editDevice')} onClose={onDrawerClose}>
			{open && pagerDetail &&
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
						{
							DEVICE_EDIT_TAB_LIST.map((item, index) => (
								<Tab key={`device_edit_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							DEVICE_EDIT_TAB_LIST.map((item, index) => (
								<TabPanel key={`pager_edit_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							))
						}
					</Box>
				</TabContext>
			}
		</RightDrawerLayout>
	);
};

export default DeviceEditPanel;