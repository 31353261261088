import AppError from '../../exception/app-error';
import { Pager } from '../../types/pager';
import { PagerType } from '../../types/pager-type';

export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';
export const FETCH_PAGER_TYPE_LIST = 'FETCH_PAGER_TYPE_LIST';
export const PAGER_TYPE_LIST_SUCCESS = 'PAGER_TYPE_LIST_SUCCESS';
export const FETCH_PAGER_LIST = 'FETCH_PAGER_LIST';
export const PAGER_LIST_SUCCESS = 'PAGER_LIST_SUCCESS';
export const FETCH_PAGER_DETAIL = 'FETCH_PAGER_DETAIL';
export const CREATE_PAGER = 'CREATE_PAGER';
export const UPDATE_PAGER = 'UPDATE_PAGER';
export const API_FAILURE = 'API_FAILURE';
export const RESET_DEVICE_STATE = 'RESET_DEVICE_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface FetchPagerTypeListAction {
  type: typeof FETCH_PAGER_TYPE_LIST;
}

export interface PagerTypeListSuccessAction {
  type: typeof PAGER_TYPE_LIST_SUCCESS;
  payload: Array<PagerType>;
}

export interface FetchPagerListAction {
  type: typeof FETCH_PAGER_LIST;
}

export interface PagerListSuccessAction {
  type: typeof PAGER_LIST_SUCCESS;
  payload: Array<Pager>;
}

export interface FetchPagerDetailAction {
  type: typeof FETCH_PAGER_DETAIL;
}

export interface CreatePagerAction {
  type: typeof CREATE_PAGER;
}

export interface UpdatePagerAction {
  type: typeof UPDATE_PAGER;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface DeviceStateResetAction {
  type: typeof RESET_DEVICE_STATE;
}

export type DeviceActionTypes = ResetErrorStateAction | FetchPagerTypeListAction | PagerTypeListSuccessAction
  | FetchPagerListAction | PagerListSuccessAction | FetchPagerDetailAction | CreatePagerAction | UpdatePagerAction
  | ApiFailureAction | DeviceStateResetAction;