import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

import CustomLabel from '../../../components/CustomLabel';
import ListItemAddRemoveCard from '../../../components/ListItemAddRemoveCard';
import LoginUtil from '../../../utils/login-util';
import SearchBar from '../../../components/SearchBar';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

/**
 * Interface for props passed to the SearchableUserList component.
 */
interface Props {
	includeLoggedInUser?: boolean;
	userList: Array<User>;
	onUserListChange: (userList: Array<User>) => void;
	subHeading?: string;
	hasPagerRestriction?: boolean;
}

/**
 * React component for searching and adding/removing users to a list.
 *
 * @param {Props} props Component props.
 * @returns {JSX.Element} The rendered component.
 */
const SearchableUserList: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const [searchKey, setSeachKey] = useState<string>('');
	const userApi = useUserApi();

	/**
   * Filters the user list based on search criteria and sorts alphabetically (if props.userList is empty).
   *
   * @returns {Array<User>} Filtered user list.
   */
	const getFilteredUserList = (): Array<User> => {
		let userList: Array<User> = [];
		if (searchKey && searchKey.length > 0 && !Util.isArrayEmpty(userApi.state.user.searchedUserList)) {
			if (props.includeLoggedInUser) {
				userList = userApi.state.user.searchedUserList;
			} else {
				userList = userApi.state.user.searchedUserList.filter(user => user.email !== LoginUtil.getLoginId());
			}
		} else if (!Util.isArrayEmpty(props.userList)) {
			userList = props.userList.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
		}

		return userList;
	}

	/**
   * Checks if a user already exists in the selected user list.
   *
   * @param {User} user User profile object.
	 * 
   * @returns {boolean} True if the user exists in the list, false otherwise.
   */
	const isExistUser = (user: User): boolean => {

		return props.userList.filter(member => member.email === user.email).length > 0;
	}

	// Get the filtered user list based on search and sorting logic.
	const filteredUserList = getFilteredUserList();

	/**
   * Handles adding or removing a user from the selected user list.
   *
   * @param {User} user User profile object.
   */
	const addOrRemoveUser = (user: User): void => {
		let newUserList: Array<User> = [...props.userList];
		if (isExistUser(user)) {
			newUserList = newUserList.filter(member => member.email !== user.email);
		} else {
			newUserList.push(user);
		}
		props.onUserListChange(newUserList);
	}

	/**
   * Handles changes in the search bar input.
   *
   * @param {string} searchKey Updated search keyword.
   */
	const onSearchChange = (searchKey: string) => {
		setSeachKey(searchKey);
		if (searchKey) {
			userApi.searchUserList(searchKey, 0, props.hasPagerRestriction);
		}
	}

	return (
		<Box sx={styles.container}>
			<CustomLabel label={isDesktop ? (props.subHeading ?? t('addMembers')) : ''} />
			<SearchBar placeholder={t('search')} handleChange={onSearchChange} />
			<Box sx={styles.contentWrapper}>
				{filteredUserList.map((user, index) => (
					<ListItemAddRemoveCard
						key={`user-profile-${index}`}
						title={user.name}
						subTitle={user.position ?? ''}
						isExistingItem={isExistUser(user)}
						onActionClick={() => addOrRemoveUser(user)}
					/>
				))}
			</Box>
		</Box>
	);
};

export default SearchableUserList;