import { Field, Form, Formik } from 'formik';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import ChallengeData from '../../types/challenge-data';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EyeIcon from '../../components/CustomIcons/EyeIcon';
import EyeOffIcon from '../../components/CustomIcons/EyeOffIcon';
import PasswordRequest from '../../types/password-request';
import PwdImageDark from '../../assets/sign_in_password_dark.svg';
import PwdImageLight from '../../assets/sign_in_password_light.svg';
import SignUpLayout from '../../layouts/SignUpLayout';
import ThemeUtil from '../../theme/theme-util';
import Util from '../../utils/util';
import ValidationItem from '../../types/ui/validation-item';
import { registeredPwdSchema } from '../../utils/validation-schema';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * ResetPasswordScreen component for resetting a user's password.
 * 
 * This component displays a form for entering a new password, validates it, and submits
 * a password reset request.
 * 
 * @returns {JSX.Element} - The rendered ResetPasswordScreen component.
 */
const ResetPasswordScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signInApi = useSignInApi();
  const challengeData: ChallengeData = location.state;
  const initialValues: PasswordRequest = {
    newPassword: ''
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validationList, setValidationList] = useState<Array<ValidationItem>>(Util.getPasswordValidationStatus(''));

  /**
   * Handles redirects if the user accesses the page directly without a valid challenge.
   * 
   * This effect checks if the `challengeData` is present in the location state.
   * If not, it redirects the user to the sign-in page.
   */
  useEffect(() => {
    if (!challengeData) {
      // If the user tries to access this page directly, redirect him to sign-in page.
      navigate(APP_NAV.SIGN_IN);
    }
  }, []);

  /**
   * Handles form submission for password reset.
   * 
   * Triggers the password reset API call with the new password and reset code.
   * 
   * @param {PasswordRequest} value - The form values containing the new password.
   */
  const onSubmit = (value: PasswordRequest): void => {
    signInApi.performPasswordReset(challengeData.challengePath, { ...value });
  }

  /**
   * Handles password input changes and updates validation status.
   * 
   * This function updates the `validationList` state with the password validation
   * status based on the new password value.
   * 
   * @param {string} value - The new password value.
   */
  const onChangeText = (value: string) => {

    setValidationList(Util.getPasswordValidationStatus(value));
  }

  return (
    <SignUpLayout
      error={signInApi.state.apiStatus?.error}
      showProgressBar={signInApi.state.apiStatus?.isLoading}
      headerTitle={t('setNewPwd')}
      image={isLightTheme ? PwdImageLight : PwdImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={registeredPwdSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid, values }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Field
              name='newPassword'
              label={t('password')}
              placeholder={t('password')}
              component={CustomInput}
              onChangeText={onChangeText}
              validationList={validationList}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                values.newPassword &&
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                    {showPassword ? <EyeOffIcon sx={styles.icon} /> : <EyeIcon sx={styles.icon} />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <CustomButton type='submit' title={t('resetPwd')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignUpLayout>
  );
};

export default ResetPasswordScreen;
