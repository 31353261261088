import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DownloadIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_7964_11862)'>
          <path d='M1.91174 12.3176V13.9646C1.91174 14.4015 2.08527 14.8204 2.39416 15.1293C2.70304 15.4382 3.12197 15.6117 3.5588 15.6117H13.4412C13.878 15.6117 14.2969 15.4382 14.6058 15.1293C14.9147 14.8204 15.0882 14.4015 15.0882 13.9646V12.3176' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4.38232 7.3764L8.49997 11.4941L12.6176 7.3764' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M8.5 1.61169V11.494' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_7964_11862'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.5 0.611694)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DownloadIcon;