import { ActualSessionStatus, SESSION_DEBOUNCE_TIME, SESSION_TIME_OUT, SessionType } from '../utils/constants';

import LoginUtil from '../utils/login-util';
import { differenceInMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useInboxApi } from '../data/inbox/api';
import { useSignInApi } from '../data/sign-in/api';

/**
 * Custom hook for managing user session and idle timeouts.
 *
 * This hook provides functionalities for handling user sessions and idle timeouts within the application.
 */
export function useSessionHandler() {

  const inboxApi = useInboxApi();
  const signInApi = useSignInApi();

  /**
   * On component mount:
   *    - Retrieves the last recorded user activity time using `LoginUtil.getLastActiveTime`.
   *    - Checks if the user has been inactive for longer than `SESSION_TIME_OUT`.
   *        - If inactive: Triggers `handleOnUserIdle` for session expiration.
   *        - If active: Calls `signInApi.refreshToken` for potential token refresh.
   *    - Cleans up on unmount: Saves the last active time if a bearer token (user login status) exists.
   */
  useEffect(() => {

    const lastActiveTime: Date | undefined = LoginUtil.getLastActiveTime();
    if (lastActiveTime && differenceInMilliseconds(new Date(), lastActiveTime) > SESSION_TIME_OUT) {
      handleOnUserIdle();
    } else {
      signInApi.refreshToken();
    }

    return () => {
      if (LoginUtil.getBearerToken()) {
        LoginUtil.saveLastActiveTime(idleTimer.getLastActiveTime() || new Date());
      }
    }
  }, []);

  /**
   * Handles the user becoming idle for the defined session timeout duration.
   *
   * This function is called by `useIdleTimer` when the user is idle for the specified
   * timeout period. It initiates session expiration logic:
   *  - Calls `performSignOut` from `useSignInApi` to log out the user from the server (if applicable).
   *  - Clears any local session details to avoid unauthorized access.
   *  - Redirects the user to the login page.
   */
  const handleOnUserIdle = () => {
    inboxApi.updateChatSessionStatus(ActualSessionStatus.Offline);
    signInApi.performSignOut(true);
  }

  /**
   * Sets the timeout duration to `SESSION_TIME_OUT`.
   * Calls `handleOnUserIdle` when the user is idle for the timeout.
   * Uses `SESSION_DEBOUNCE_TIME` as a debounce value to prevent immediate actions after inactivity.
   */
  const idleTimer = useIdleTimer({
    timeout: SESSION_TIME_OUT,
    onIdle: handleOnUserIdle,
    debounce: SESSION_DEBOUNCE_TIME
  });

}