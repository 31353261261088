import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the AssignDevicePanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      px: Dimens.spacing.section,
      [theme.breakpoints.down('sm')]: {
        px: Dimens.spacing.lg
      }
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto'
    }
  };

  return styles;
};

export default useStyles;
