import { Avatar, Badge, Box, IconButton, ListItemAvatar, Stack } from '@mui/material';

import { CONVERSATION_SUB_MENU } from '../../../../utils/ui-constants';
import ChatUtil from '../../../../utils/chat-util';
import ConversationData from '../../../../types/conversation-data';
import Dimens from '../../../../theme/dimens';
import DotsVerticalIcon from '../../../../components/CustomIcons/DotsVerticalIcon';
import { InteractionType } from '../../../../utils/constants';
import ListItemButton from '@mui/material/ListItemButton';
import LoginUtil from '../../../../utils/login-util';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import React from 'react';
import Typography from '@mui/material/Typography';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface for InboxListItem component props
 * 
 * @interface Props - The properties expected by the InboxListItem component.
 */
interface Props {
  /**
   * Conversation data object containing information about the conversation.
   */
  conversation: ConversationData;
  /**
   * Callback function to handle item selection (likely for navigation).
   */
  onItemSelected: (conversation: ConversationData) => void;
}

/**
 * InboxListItem Component
 * 
 * This component renders a single conversation item within the inbox list.
 * It displays the contact name/group name, last message/group creation info, unread message count, and a menu icon.
 */
const InboxListItem: React.FC<Props> = (props: Props) => {

  const { conversation, onItemSelected } = props;
  const styles = useStyles(props.conversation);
  const { t } = useTranslation();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * getName function
   * 
   * This function determines the name to display for the conversation item
   * based on the conversation type.
   * 
   * - For individual conversations, it returns the sender name.
   * - For group conversations, it returns the group name.
   * - If no name is available, it returns an empty string.
   *  
   * @returns {string} The name to display for the conversation item.
   */
  const getName = (): string => {
    let name = conversation.recipientName;
    if (conversation.interactionType === InteractionType.Group) {
      name = conversation.name;
    }

    return name || '';
  }

  /**
	 * Generates initials from the conversation name.
	 *
	 * If the record represents a group room, uses a single character as initials.
	 * Otherwise, uses the first two characters of the conversation name.
	 *
	 * @returns {string} The generated initials.
	 */
  const getInitialsFromName = () => {
		const charCount = conversation.groupRoomKey ? 1 : 2;

		return Util.getInitialsFromName(getName(), charCount);
	}

  /**
   * getMessage function
   * 
   * This function determines the message to display for the conversation item
   * based on the conversation type.
   * 
   * - For individual conversations, it returns the last message content text.
   * - For group conversations, if there is no last message, it constructs a message indicating 
   *   who created the group (you or another user).
   *  
   * @returns {string} The message to display for the conversation item.
   */
  const getMessage = (): string => {
    let message = conversation.lastContentText ?? '';
    if (conversation.interactionType === InteractionType.Group) {
      if (!message) {
        if (conversation.createdByLoginId) {
          const groupCreator: string = ((conversation.createdByLoginId) === LoginUtil.getLoginId()) ?
          t('you') : (conversation.createdByName ?? '');
        message = `${groupCreator} ${t('createdGroup')}`;
        } else {
          message = t('youAddedToGroup');
        }
      }
    }

    return message;
  }

  /**
   * Handles the click event on the submenu button.
   * Prevents event propagation to prevent unintended menu closing.
   * Sets the anchor element for the submenu.
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event.
   */
  const onSubmenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setSubMenuAnchorEl(event.currentTarget);
  }

  /**
   * Handles the click event on a submenu item.
   * Closes the submenu by setting the anchor element to null.
   */
  const handleSubMenuItemClick = () => {
    setSubMenuAnchorEl(null);
  }

  return (
    <>
      <ListItemButton onClick={() => onItemSelected(conversation)} sx={styles.listItem} disableRipple>
        <ListItemAvatar sx={styles.avatarWrapper}>
          <Badge variant='dot' overlap='circular' sx={styles.badge}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Avatar sx={styles.avatar}>
              <Typography variant='p3' >
                {getInitialsFromName()}
              </Typography>
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <Box sx={styles.contentWrapper}>
          <Stack sx={styles.headerWrapper}>
            <Typography variant='p1' sx={styles.primaryText}>
              {getName()}
            </Typography>
            <Typography variant='caption' sx={styles.date}>
              {ChatUtil.getChatFormatDate(conversation.lastContentTime || conversation.createdAt)}
            </Typography>
          </Stack>
          <Stack sx={styles.detailsWrapper}>
            <Typography variant='p1' sx={styles.secondaryText}>
              {conversation.lastContentText && ChatUtil.isGroupChat(conversation) &&
                <Typography variant='p1' component='span' fontWeight={Dimens.fontWeight.medium}>
                  {`${ChatUtil.isLoggedInUser(conversation.lastSenderLoginId ?? '') ? t('you') : conversation.lastSenderName}: `}
                </Typography>
              }
              {getMessage()}
            </Typography>
            <Stack sx={styles.actionWrapper}>
              <IconButton sx={styles.iconToggle} onClick={onSubmenuClick}>
                <DotsVerticalIcon />
              </IconButton>
              <Typography variant='footer' sx={styles.counter}>
                {conversation.count}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </ListItemButton>
      <MenuSelectDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={CONVERSATION_SUB_MENU}
        onMenuItemSelected={handleSubMenuItemClick}
      />
    </>
  );
};

export default InboxListItem;