import {
	API_FAILURE,
	ApiFailureAction,
	CREATE_PAGER,
	CreatePagerAction,
	DeviceStateResetAction,
	FETCH_PAGER_DETAIL,
	FETCH_PAGER_LIST,
	FETCH_PAGER_TYPE_LIST,
	FetchPagerDetailAction,
	FetchPagerListAction,
	FetchPagerTypeListAction,
	PAGER_LIST_SUCCESS,
	PAGER_TYPE_LIST_SUCCESS,
	PagerListSuccessAction,
	PagerTypeListSuccessAction,
	RESET_DEVICE_STATE,
	RESET_ERROR_STATE,
	ResetErrorStateAction,
	UPDATE_PAGER,
	UpdatePagerAction
} from './action-types';

import AppError from '../../exception/app-error';
import { Pager } from '../../types/pager';
import { PagerType } from '../../types/pager-type';

/**
 * Resets the error state.
 *
 * @returns {ResetErrorStateAction} A ResetErrorStateAction object.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Action creator to indicate the start of a pager list search.
 *
 * @returns {FetchPagerTypeListAction} An action object indicating the search initiation.
 */
export const initPagerTypeListFetch = (): FetchPagerTypeListAction => ({
  type: FETCH_PAGER_TYPE_LIST
});

/**
 * Action creator to indicate the start of fetching pager details.
 *
 * @returns {FetchPagerDetailAction} An action object indicating the start of fetching pager details.
 */
export const initPagerDetailFetch = (): FetchPagerDetailAction => ({
  type: FETCH_PAGER_DETAIL
});

/**
 * Initializes the create pager action.
 *
 * @returns {CreatePagerAction} The create pager action object.
 */
export const initCreatePager = (): CreatePagerAction => ({
	type: CREATE_PAGER
});

/**
 * Initializes the update pager action.
 *
 * @returns {UpdatePagerAction} The update pager action object.
 */
export const initUpdatePager = (): UpdatePagerAction => ({
	type: UPDATE_PAGER
});

/**
 * Action creator to indicate successful pager type list retrieval.
 *
 * @param {Array<Pager>} pagerList - The list of pager types retrieved.
 * 
 * @returns {PagerTypeListSuccessAction} An action object with the pager type list payload.
 */
export const pagerTypeListSuccess = (pagerTypeList: Array<PagerType>): PagerTypeListSuccessAction => ({
	type: PAGER_TYPE_LIST_SUCCESS,
	payload: pagerTypeList
});

/**
 * Initiates fetching the pager list.
 *
 * @returns {FetchPagerListAction} A FetchPagerListAction object.
 */
export const initPagerListFetch = (): FetchPagerListAction => ({
	type: FETCH_PAGER_LIST
});

/**
 * Sets the pager list after successful fetching.
 *
 * @param {Array<PagerProfile>} pagerList The fetched pager list.
 * 
 * @returns {PagerListSuccessAction} A PagerListSuccessAction object with the pager list payload.
 */
export const pagerListSuccess = (pagerList: Array<Pager>): PagerListSuccessAction => ({
	type: PAGER_LIST_SUCCESS,
	payload: pagerList
});

/**
 * Handles API failure with an error.
 *
 * @param {AppError} error The encountered error.
 * 
 * @returns {ApiFailureAction} An ApiFailureAction object with the error payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * Resets the device state.
 *
 * @returns {DeviceStateResetAction} A DeviceStateResetAction object.
 */
export const resetDeviceState = (): DeviceStateResetAction => ({
	type: RESET_DEVICE_STATE
});