import Dimens from '../../../theme/dimens';
import { IDocument } from '@cyntler/react-doc-viewer';
import InboxPlaceholderDark from '../../../assets/inbox_placeholder_dark.svg';
import InboxPlaceholderLight from '../../../assets/inbox_placeholder_light.svg';
import ThemeUtil from '../../../theme/theme-util';
import Util from '../../../utils/util';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for InboxDetailScreen Styles
 * 
 * This hook defines the styles used by the InboxDetailScreen component.
 */
const useStyles = (showBackToStartBtn = false, isAttachmentView = false, document: Array<IDocument>) => {

  const theme = useTheme();
  const isLightTheme = ThemeUtil.isLightTheme(theme);

  const styles = {
    wrapper: {
      display: 'flex',
      height: 'inherit'
    },
    innerWrapper: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      bgcolor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        borderLeft: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`
      }
    },
    content: {
      display: Util.isArrayEmpty(document) ? 'flex' : 'none',
      flexDirection: 'column-reverse',
      flex: 1,
      p: Dimens.spacing.section,
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        p: Dimens.spacing.lg
      }
    },
    contentSub: {
      position: isAttachmentView ? 'unset': 'relative',
      display: Util.isArrayEmpty(document) ? 'none' : 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        p: Dimens.spacing.lg
      }
    },
    icon: {
      fontSize: Dimens.icon.sm
    },
    image: {
      flex: 1,
      backgroundImage: `url(${isLightTheme ? InboxPlaceholderLight : InboxPlaceholderDark})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
    fab: {
      position: 'absolute',
      bottom: Dimens.fabMargin,
      right: 0,
      borderRadius: 0,
      borderTopLeftRadius: Dimens.radius.md,
      borderBottomLeftRadius: Dimens.radius.md,
      boxShadow: 'none',
      display: showBackToStartBtn ? 'flex' : 'none'
    },
    loadingTxt: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
      pb: Dimens.spacing.section,
      [theme.breakpoints.down('sm')]: {
        pb: Dimens.spacing.lg
      }
    },
    sendingText: {
      color: theme.palette.text.secondary,
      p: Dimens.spacing.xxs,
      textAlign: 'center'
    }
  };

  return styles;
};

export default useStyles;