import { Box, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import CustomButton from '../../components/CustomButton';
import Department from '../../types/department';
import DepartmentImageDark from '../../assets/sign_up_department_dark.svg';
import DepartmentImageLight from '../../assets/sign_up_department_light.svg';
import SignUpLayout from '../../layouts/SignUpLayout';
import SignUpPayload from '../../types/ui/sign-up-payload';
import SiteMapIcon from '../../components/CustomIcons/SiteMapIcon';
import ThemeUtil from '../../theme/theme-util';
import { registerDepartmentSchema } from '../../utils/validation-schema';
import { useDepartmentApi } from '../../data/department/api';
import { useDisableBackNav } from '../../hooks/use-disable-back-nav';
import { useSignUpApi } from '../../data/sign-up/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface DepartmentRegisterRequest {
  department: Department | null;
}

/**
 * RegisterDepartmentScreen component
 *
 * Handles the department registration step of the sign-up process.
 * Allows users to select a department from a list or add a new one.
 *
 * @returns JSX.Element - The rendered department registration screen.
 */
const RegisterDepartmentScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signUpApi = useSignUpApi();
  const deptApi = useDepartmentApi();
  useDisableBackNav();
  const challengePayload: SignUpPayload = location.state;
  const initialValues: DepartmentRegisterRequest = {
    department: null
  };

  /**
   * Fetches department list on component mount if `challengePayload.isValidSignUpRequest` is true.
   * Navigates to welcome page if the challenge payload is invalid (Unauthorized access).
   */
  useEffect(() => {
    if (challengePayload?.isValidSignUpRequest) {
      deptApi.fetchDepartmentList();
    } else {
      navigate(APP_NAV.WELCOME);
    }
  }, []);

  const onAddNewDeptClick = () => {
    navigate(APP_NAV.SIGN_UP_NEW_DEPARTMENT, {
      state: challengePayload
    });
  }

  /**
   * Handles form submission.
   * 
   * @param {DepartmentRegisterRequest} value - Form values.
   */
  const onSubmit = (value: DepartmentRegisterRequest): void => {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    signUpApi.registerDepartment(value.department!);
  }

  return (
    <SignUpLayout
      error={signUpApi.state.error}
      showProgressBar={signUpApi.state.isLoading}
      headerTitle={t('addDepartment')}
      image={isLightTheme ? DepartmentImageLight : DepartmentImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={registerDepartmentSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box>
              <Field
                name='department'
                label={t('departments')}
                placeholder={t('findDepartments')}
                component={CustomAutocomplete}
                noOptionsText={t('noDepts')}
                menu={deptApi.state.department.departmentList}
              />
              {(challengePayload?.isValidSignUpRequest && challengePayload?.payload?.canCreateDepartment) &&
                <CustomButton
                  title={t('addNewDept')}
                  color='primary'
                  fullWidth
                  startIcon={<SiteMapIcon sx={styles.icon} />}
                  onClick={onAddNewDeptClick}
                />
              }
            </Box>
            <CustomButton type='submit' title={t('next')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignUpLayout>
  );
};

export default RegisterDepartmentScreen;

