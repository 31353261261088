import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6408_4086)'>
          <path d='M1 8.21875L15 8.21875' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
          <path d='M8 1.21875V15.2187' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_6408_4086'>
            <rect width='16' height='16' fill='transparent' transform='translate(0 0.21875)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default AddIcon;