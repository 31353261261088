import { AppBar, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';

import ChevronLeftIcon from '../../../components/CustomIcons/ChevronLeftIcon';
import React from 'react';
import theme from '../../../theme/theme';
import useStyles from './styles';

interface Props {
	hasBackButton?: boolean;
	title: string;
	onBackClick?: () => void;
}
/**
 * Renders a header for the settings page. It optionally displays a back button
 * and always shows the title provided via props.
 *
 * @param {Props} props - Component props:
 *   - hasBackButton (optional): Determines if a back button should be shown.
 *   - title: The title to display in the header.
 *   - onBackClick (optional): Callback function for back button clicks.
 *
 * @returns {JSX.Element} JSX element representing the settings header.
 */
const SettingsHeader: React.FC<Props> = (props: Props) => {
	
	const styles = useStyles();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<AppBar position='static' sx={styles.appBar}>
			<Toolbar>
				{props.hasBackButton && (
					<IconButton edge='start' sx={styles.iconBtn} onClick={props.onBackClick} aria-label='back'>
						<ChevronLeftIcon sx={styles.icon} />
					</IconButton>
				)}
				<Typography variant={isSmallScreen ? 'h3' : 'h2'} sx={styles.title}>
					{props.title}
				</Typography>
			</Toolbar>
		</AppBar>
	);
};

export default SettingsHeader;
