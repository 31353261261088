import Dimens from '../../../../../theme/dimens';
import { MessageSendStatus } from '../../../../../utils/constants';
import { useTheme } from '@mui/material';

/**
 * useStyles hook
 * 
 * This hook creates a styles object containing styling information for the 
 * `RightChatItem` component.
 */
const useStyles = (isUrgent = false, disableMessageClick = false, msgStatus?: MessageSendStatus) => {

  const { xxs, base, lg } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
		wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      mb: lg
    },
    contentWrapper: {
      alignItems: 'end',
      flex: 1
    },
    content: {
      maxWidth: '70%',
      bgcolor: isUrgent ? theme.palette.error.main : theme.palette.primary.main,
      p: lg,
      mb: xxs,
      borderRadius: `${lg} ${lg} ${xxs} ${lg}`,
      color: isUrgent ? theme.palette.error.contrastText : theme.palette.primary.contrastText,
      cursor: disableMessageClick ? 'unset' : 'pointer',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
      }
    },
    contentText: {
      whiteSpace: 'pre-wrap',
      display: '-webkit-box',
      wordWrap: 'break-word',
      WebkitBoxOrient: 'vertical',
      overflowWrap: 'anywhere'
    },
    icon: {
      fontSize: Dimens.icon.sm,
      color: () => {
        let color = theme.palette.primary.main;
        if (msgStatus === MessageSendStatus.InProgress || msgStatus === MessageSendStatus.FileUpload) {
          color = theme.palette.primary.contrastText;
        } else if (isUrgent || msgStatus === MessageSendStatus.Failed) {
          color = theme.palette.error.main;
        }
        
        return color;
      },
      ml: base
    },
    deliveredIcon: {
      fontSize: Dimens.icon.sm,
      color: isUrgent ? theme.palette.error.light : theme.palette.primary.light,
      ml: base
    },
    retry: {
      color: theme.palette.error.main,
      cursor: 'pointer'
    }
  };

  return styles;
};

export default useStyles;