import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SettingsMenuList component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {
	const theme = useTheme();

	const styles = {
		container: {
			p: 0,
			borderRadius: Dimens.spacing.xs,
			overflow: 'hidden'
		},
		listContainer:{
			p: 0
		},		
		menuItem: {
			cursor: 'pointer',
			minWidth: Dimens.menuItemMinWidth,
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary,
			'&:hover': {
				backgroundColor: theme.palette.secondary.dark
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.secondary.dark,
				'&:hover': {
					backgroundColor: theme.palette.secondary.dark
				}
			}
		},
		iconWrapper: {
			'&.MuiListItemIcon-root': {
				minWidth: '0 !important',
				ml: Dimens.spacing.base,
				p: 0,
				color: 'inherit',
				'& .MuiSvgIcon-root': {
					fontSize: Dimens.icon.sm
				}
			}
		},
		divider: {
			my: '0 !important',
			borderBottomWidth: Dimens.menuBorderWidth,
			borderColor: theme.palette.background.paper
		}
	};

	return styles;
};

export default useStyles;
