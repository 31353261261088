import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomInput from '../../../../components/CustomInput';
import React from 'react';
import { User } from '../../../../types/user';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	userDetail: User;
}

/**
 * ProfileDetails component for displaying details of logged in user.
 *
 * @param {Props} props - Component props.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ProfileDetails: React.FC<Props> = (props: Props) => {

	const { userDetail } = props;
	const styles = useStyles();
	const { t } = useTranslation();

	const initialValues = {
		...userDetail,
		pagerName: userDetail.pager ? userDetail.pager.name : ''
	};

	return (
		<Grid sx={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={() => {/* Do Nothing */ }}>
				<Form style={styles.form as React.CSSProperties}>
					<Box sx={styles.formInnerWrapper}>
						<Field
							name='about'
							label={t('aboutMe')}
							placeholder={t('aboutYouPlaceholder')}
							component={CustomInput}
							maxRows={Number.MAX_SAFE_INTEGER}
							readOnly
							multiline
						/>
						{!Util.isArrayEmpty(userDetail.sites) && (
							<Field
								name='sites'
								label={t('sites')}
								placeholder={t('findSites')}
								component={CustomAutocomplete}
								multiple
								menu={[]}
								readOnly
							/>
						)}
						<Field
							name='departments'
							label={t('departments')}
							placeholder={t('findDepartments')}
							component={CustomAutocomplete}
							multiple
							defaultOptionId={userDetail.defaultDepartmentId}
							menu={[]}
							readOnly
						/>
						{userDetail.pager && userDetail.pager.name && (
							<Field
								name='pagerName'
								label={t('pager')}
								placeholder={t('pager')}
								component={CustomInput}
								readOnly
							/>
						)}
					</Box>
				</Form>
			</Formik>
		</Grid>
	);
};

export default ProfileDetails;
