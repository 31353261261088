/**
 * Application navigation constants.
 *
 * This file defines constant values for various application routes.
 * These constants are used throughout the application to navigate users
 * to different screens or pages.
 *
 * It's recommended to use these constants instead of hardcoding route strings
 * for better maintainability and easier updates to application routes.
 */
const APP_NAV = {
  SPLASH_SCREEN: '/challenges',
  WELCOME: '/welcome',
  SIGN_UP_PASSWORD: '/sign-up/password',
  SIGN_UP_DEPARTMENT: '/sign-up/department',
  SIGN_UP_NEW_DEPARTMENT: '/sign-up/department/new',
  SIGN_UP_PROFILE: '/sign-up/profile',
  SIGN_IN: '/sign-in',
  SIGN_IN_PASSWORD: '/sign-in/password',
  SIGN_IN_VERIFY: '/sign-in/verify',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset',
  INBOX: '/inbox',
  INBOX_DETAIL_REL_PATH: '/detail',
  DIRECTORY: '/directory',
  ADMIN: '/admin',
  NOTIFICATIONS: '/notifications',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  ADMIN_USERS: '/admin/users',
  ADMIN_DEPARTMENTS: '/admin/departments',
  ADMIN_SETTINGS: '/admin/settings',
  ADMIN_SETTINGS_DOMAINS: '/admin/settings/domains',
  ADMIN_SETTINGS_PERMISSIONS: '/admin/settings/permissions',
  ADMIN_SETTINGS_SITES: '/admin/settings/sites',
  ADMIN_DEVICES: '/admin/devices',
  ADMIN_LOGS: '/admin/logs',
  ADMIN_LOGS_CALL: '/admin/logs/call',
  ADMIN_LOGS_SYSTEM: '/admin/logs/system'
} 

export default APP_NAV;