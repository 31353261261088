import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronRightIcon = (props: SvgIconProps) => {

	return (
		<SvgIcon {...props}>
			<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M5.66797 2.5L11.668 8.5L5.66797 14.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			</svg>
		</SvgIcon>
	);
};

export default ChevronRightIcon;