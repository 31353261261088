import { Box, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../../routes/app-nav';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import Department from '../../../types/department';
import DepartmentImageDark from '../../../assets/sign_up_department_dark.svg';
import DepartmentImageLight from '../../../assets/sign_up_department_light.svg';
import { MAX_MULTI_LINE_CHAR_COUNT } from '../../../utils/constants';
import SignUpLayout from '../../../layouts/SignUpLayout';
import SignUpPayload from '../../../types/ui/sign-up-payload';
import ThemeUtil from '../../../theme/theme-util';
import { registerNewDeptSchema } from '../../../utils/validation-schema';
import { useSignUpApi } from '../../../data/sign-up/api';
import { useSiteApi } from '../../../data/site/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * RegisterNewDepartmentScreen component
 *
 * Handles the department registration step of the sign-up process.
 * Allows users to create a new department.
 *
 * @returns JSX.Element - The rendered new department registration screen.
 */
const RegisterNewDepartmentScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signUpApi = useSignUpApi();
  const siteApi = useSiteApi();
  const challengePayload: SignUpPayload = location.state;
  const initialValues: Department = {
    id: '',
    name: '',
    description: '',
    sites: []
  };

  /**
   * Fetches department list on component mount if `challengePayload.isValidSignUpRequest` is true.
   * Navigates to welcome page if the challenge payload is invalid (Unauthorized access).
   */
  useEffect(() => {
    if (challengePayload?.isValidSignUpRequest) {
      siteApi.fetchSiteList();
    } else {
      navigate(APP_NAV.WELCOME);
    }
  }, []);

  /**
   * Handles form submission.
   * 
   * @param {Department} department - Form values.
   */
  const onSubmit = (department: Department): void => {
    signUpApi.registerDepartment(department);
  }

  return (
    <SignUpLayout
      error={signUpApi.state.error}
      showProgressBar={signUpApi.state.isLoading}
      headerTitle={t('newDepartment')}
      image={isLightTheme ? DepartmentImageLight : DepartmentImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={registerNewDeptSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box>
              <Field
                name='name'
                label={t('name')}
                placeholder={t('name')}
                component={CustomInput}
              />
              <Field
                name='description'
                label={t('description')}
                subLabel={t('optional')}
                placeholder={t('deptDescPlaceholder')}
                multiline
                maxCharCount={MAX_MULTI_LINE_CHAR_COUNT}
                component={CustomInput}
              />
              <Field
                name='sites'
                label={t('sites')}
                subLabel={t('optional')}
                placeholder={t('findSites')}
                component={CustomAutocomplete}
                multiple
                noOptionsText={t('noSites')}
                menu={siteApi.state.site.siteList}
                showDropdownIcon
              />
            </Box>
            <CustomButton type='submit' title={t('next')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignUpLayout>
  );
};

export default RegisterNewDepartmentScreen;

