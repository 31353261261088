import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import ThemeUtil from '../../theme/theme-util';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CustomButton component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (isDestructive = false, small = false, title = '') => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { sm, base, md, lg } = Dimens.spacing;
  
  const styles = {
    base: {
      lineHeight: 0,
      textTransform: 'none',
      borderRadius: Dimens.radius.md,
      padding: small ? `${sm} ${base}` : `${md} ${lg}`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      'span': {
        '&.MuiButton-startIcon': {
          minWidth: title ? undefined : 0,
          m: title ? undefined : 0,
          'svg': {
            ...commonStyles.smIcon
          }
        },
        '&.MuiButton-endIcon': {
          minWidth: title ? undefined : 0,
          m: title ? undefined : 0,
          'svg': {
            ...commonStyles.smIcon
          }
        }
      },
      '&.MuiButton-colorPrimary': {
        backgroundColor: isDestructive ? theme.palette.error.main : 'primary',
        '&.Mui-disabled': {
          backgroundColor: isDestructive ? theme.palette.error.light : theme.palette.primary.light,
          color: ThemeUtil.isLightTheme(theme) ? Colors.white : Colors.palette.secondary[600],
          pointerEvents: 'all',
          cursor: 'not-allowed'
        }
      },
      '&.MuiButton-colorSecondary': {
        color: isDestructive ? theme.palette.error.dark : 'secondary',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.secondary.main,
          color: ThemeUtil.isLightTheme(theme) ? Colors.palette.secondary[200] : Colors.palette.secondary[700],
          pointerEvents: 'all',
          cursor: 'not-allowed'
        }
      }
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: Dimens.fontWeight.medium
    }
  };

  return styles;
};

export default useStyles;
