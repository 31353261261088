import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SwapIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6572_31931)'>
          <path d='M1 1.77778C1 1.5715 1.08194 1.37367 1.22781 1.22781C1.37367 1.08194 1.5715 1 1.77778 1H4.8889C5.09518 1 5.29301 1.08194 5.43887 1.22781C5.58473 1.37367 5.66668 1.5715 5.66668 1.77778V4.88888C5.66668 5.09516 5.58473 5.29299 5.43887 5.43886C5.29301 5.58472 5.09518 5.66666 4.8889 5.66666H1.77778C1.5715 5.66666 1.37367 5.58472 1.22781 5.43886C1.08194 5.29299 1 5.09516 1 4.88888V1.77778Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M10.334 11.1098C10.334 10.9035 10.4159 10.7057 10.5618 10.5598C10.7077 10.414 10.9055 10.332 11.1118 10.332H14.2229C14.4292 10.332 14.627 10.414 14.7729 10.5598C14.9187 10.7057 15.0007 10.9035 15.0007 11.1098V14.2209C15.0007 14.4272 14.9187 14.625 14.7729 14.7709C14.627 14.9167 14.4292 14.9987 14.2229 14.9987H11.1118C10.9055 14.9987 10.7077 14.9167 10.5618 14.7709C10.4159 14.625 10.334 14.4272 10.334 14.2209V11.1098Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M14.9996 7.22222V4.88888C14.9996 4.47633 14.8357 4.08066 14.544 3.78894C14.2522 3.49722 13.8566 3.33333 13.444 3.33333H8.77734M8.77734 3.33333L11.1107 5.66666M8.77734 3.33333L11.1107 1' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1 8.77734V11.1107C1 11.5232 1.16389 11.9189 1.45561 12.2106C1.74734 12.5023 2.143 12.6662 2.55556 12.6662H7.22223M7.22223 12.6662L4.8889 10.3329M7.22223 12.6662L4.8889 14.9996' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6572_31931'>
            <rect width='16' height='16' fill='transparent' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default SwapIcon;