import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

import APP_NAV from '../../routes/app-nav';
import AppreranceOptions from './SettingMenu/ApperanceSubMenu';
import EditProfileSubMenu from './SettingMenu/EditProfileSubMenu';
import HelpSubMenu from './SettingMenu/HelpSubMenu';
import LanguageOptions from './SettingMenu/LanguageSubMenu';
import NotificationsSubMenu from './SettingMenu/NotificationSubMenu';
import { SETTINGS_PARENT_MENU } from '../../utils/ui-constants';
import SecurityOptions from './SettingMenu/SecuritySubMenu';
import SettingOptions from './SettingMenu';
import SettingsHeader from './SettingHeader';
import SettingsImageDark from '../../assets/inbox_placeholder_dark.svg';
import SettingsImageLight from '../../assets/inbox_placeholder_light.svg';
import SettingsMenuData from '../../types/ui/settings_menu_data';
import { SettingsSubMenu } from '../../utils/constants';
import ThemeUtil from '../../theme/theme-util';
import { t } from 'i18next';
import theme from '../../theme/theme';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

/**
 * SettingScreen Component
 * 
 * The `SettingScreen` component serves as the main settings screen for the application. It displays a navigation menu
 * on the left and corresponding content based on the selected menu item. The screen adapts to light and dark themes
 * and handles navigation and content rendering dynamically based on user interactions and screen size.
 * 
 * @component
 * @returns {JSX.Element} JSX element representing the settings screen.
 */
const SettingScreen: React.FC = () => {

	const styles = useStyles();
	const isLightTheme = ThemeUtil.isLightTheme(useTheme());
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [ selectedMenu, setSelectedMenu ] = useState<SettingsMenuData>(SETTINGS_PARENT_MENU[ 0 ]);
	const navigate = useNavigate();

	/**
	 * Handles back button click behavior:
	 * - If on a small screen and inside the 'EditProfile' submenu, it checks if there are unsaved profile changes.
	 * - If no unsaved changes, navigates back to the profile page.
	 * - On larger screens, it resets the selected menu to the main settings menu.
	 */
	const handleBackClick = () => {
		if (isSmallScreen && selectedMenu.id === SettingsSubMenu.Settings) {
			navigate(APP_NAV.PROFILE);  // Navigate to profile page
		} else {
			setSelectedMenu(SETTINGS_PARENT_MENU[ 0 ]);  // Reset to main settings menu
		}
	};

	/**
	 * Handles the selection of a menu item from the settings navigation.
	 * Updates the selected menu state with the chosen item.
	 * 
	 * @param {SettingsMenuData} item - The selected menu item.
	 */
	const handleMenuSelect = (item: SettingsMenuData) => {
		setSelectedMenu(item);
	};


	/**
	 * Renders content based on the selected menu item. Uses a switch statement to conditionally render the appropriate
	 * submenu component, such as appearance settings, profile editing, security options, etc.
	 * 
	 * @returns {JSX.Element} The component representing the content for the selected menu item.
	 */
	const displaySelectedMenuComponent = () => {
		switch (selectedMenu?.id) {
			case SettingsSubMenu.Settings:
				return <SettingOptions onSettingChange={handleMenuSelect} />;
			case SettingsSubMenu.Appearance:
				return <AppreranceOptions />;
			case SettingsSubMenu.EditProfile:
				return <EditProfileSubMenu />;
			case SettingsSubMenu.Security:
				return <SecurityOptions />;
			case SettingsSubMenu.Notifications:
				return <NotificationsSubMenu />;
			case SettingsSubMenu.Language:
				return <LanguageOptions />;
			case SettingsSubMenu.Help:
				return <HelpSubMenu />;
			default:
				return <SettingOptions onSettingChange={handleMenuSelect} />;
		}
	};

	return (
		<Grid container sx={styles.wrapper}>
			<Box sx={styles.leftPane}>
				<SettingsHeader
					title={selectedMenu ? selectedMenu.value.toString() : t('settings')}
					hasBackButton={isSmallScreen || (!isSmallScreen && selectedMenu.id !== SettingsSubMenu.Settings)}
					onBackClick={handleBackClick} />
				{displaySelectedMenuComponent()}
			</Box>
			<Box sx={styles.rightPane}>
				<Box
					sx={{
						...styles.image,
						backgroundImage: `url(${isLightTheme ? SettingsImageLight : SettingsImageDark})`,
					}}
				/>
			</Box>
		</Grid>
	);
};

export default SettingScreen;
