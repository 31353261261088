import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import MenuList from '../../SettingsMenuList';
import { SETTINGS_NOTIFICATIONS_MENU_OPTIONS } from '../../../../utils/ui-constants';
import SettingsMenuData from '../../../../types/ui/settings_menu_data';
import useStyles from './styles';

/**
 * NotificationsSubMenu component renders a list of notification-related menu options 
 * using the MenuList component.
 * 
 * The component handles user interaction through the onItemClick callback, logging the 
 * selected item to the console.
 *
 * @returns {JSX.Element} JSX element representing the notifications submenu.
 */
const NotificationsSubMenu: React.FC = () => {

	const styles = useStyles();
	const [ menuOptions, setMenuOptions ] = useState<Array<SettingsMenuData>>([]);

	useEffect(() => {
		setMenuOptions(SETTINGS_NOTIFICATIONS_MENU_OPTIONS.map(option => ({
			...option,
			toggleValue: Boolean(option.toggleValue), // Set default if not defined
		})));
	}, []);

	/**
	 * Toggles the `toggleValue` property of a menu item in the state when the toggle switch is changed.
	 * It updates the menu options by finding the item with the same `id` as the one passed in, 
	 * and inverts its `toggleValue`.
	 *
	 * @param {SettingsMenuData} item - The menu item whose toggle switch was changed.
	 * 
	 * @returns {void} This function updates the state of the menu options with the modified toggle value.
	 */
	const handleToggleChange = (item: SettingsMenuData) => {
		setMenuOptions((prevMenuOptions) =>
			prevMenuOptions.map((option) =>
				option.id === item.id
					? { ...option, toggleValue: !option.toggleValue }
					: option
			)
		);
	};

	return (
		<Box sx={styles.container}>
			<MenuList
				menuItems={menuOptions}
				onItemClick={handleToggleChange}
			/>
		</Box>
	);
};

export default NotificationsSubMenu;
