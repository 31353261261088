import useCommonStyles from '../../../theme/use-common-styles';

/**
 * Creates styles for the DeviceListTableRow component.
 * 
 * @returns {object} An object containing styles for the table row and option menu button.
 */
const useStyles = () => {

  const commonStyles = useCommonStyles();

  const styles = {
    tableRow: {
      cursor: 'pointer'
    },
    optionMenuBtn: {
      justifyContent: 'end',
      '& svg':{
        ...commonStyles.smIcon
      }
    }
  };

  return styles;
};

export default useStyles;
