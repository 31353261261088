import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PdfIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M17.8828 18.9883H20.2146' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.793 1.25V6.02777C13.793 6.34456 13.9188 6.64837 14.1428 6.87237C14.3668 7.09637 14.6706 7.22222 14.9874 7.22222H19.7652' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.04297 12V3.63889C3.04297 3.00531 3.29465 2.39769 3.74266 1.94969C4.19066 1.50169 4.79828 1.25 5.43186 1.25H13.793L19.7652 7.22222V12' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.04297 19.1654H4.83463C5.30981 19.1654 5.76553 18.9766 6.10153 18.6406C6.43753 18.3046 6.6263 17.8489 6.6263 17.3737C6.6263 16.8985 6.43753 16.4428 6.10153 16.1068C5.76553 15.7708 5.30981 15.582 4.83463 15.582H3.04297V22.7487' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M20.9583 15.582H17.375V22.7487' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.207 15.582V22.7487H11.4015C12.035 22.7487 12.6427 22.497 13.0907 22.049C13.5387 21.601 13.7904 20.9934 13.7904 20.3598V17.9709C13.7904 17.3373 13.5387 16.7297 13.0907 16.2817C12.6427 15.8337 12.035 15.582 11.4015 15.582H10.207Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default PdfIcon;