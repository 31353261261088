import React, { useContext, useEffect } from 'react';

import APIConfig from '../../../service/api-config';
import { ActualSessionStatus } from '../../../utils/constants';
import { Box } from '@mui/material';
import ContentLayout from './ContentLayout';
import DrawerNavigator from './DrawerNavigator';
import { SessionExpirationContext } from '../../../store/session-expiration-provider';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useInboxApi } from '../../../data/inbox/api';
import { useSessionHandler } from '../../../hooks/use-session-handler';
import { useSignInApi } from '../../../data/sign-in/api';
import useStyles from './styles';

export interface MainProps {
  children: React.ReactNode;
}

/**
 * Main Component
 * 
 * This component renders the main layout for the application.
 * It includes the DrawerNavigator component, content wrapper, and child content.
 * 
 * @param {MainProps} props - Component properties with children (content to display).
 * @returns {JSX.Element} - The JSX element for the Main component.
 */
const Main: React.FC<MainProps> = (props: MainProps) => {

  const styles = useStyles();
  // Call session handler hook for session/idle time management.
  useSessionHandler();
  const { isSessionExpired } = useContext(SessionExpirationContext);
  const inboxApi = useInboxApi();
  const signInApi = useSignInApi();

  /**
   * Handles session expiration.
   *
   * This effect checks if the `isSessionExpired` state is true. If so, it updates the chat session status to "Offline" and performs sign-out.
   */
  useEffect(() => {
    if (isSessionExpired) {
      inboxApi.updateChatSessionStatus(ActualSessionStatus.Offline);
      signInApi.performSignOut(true);
    }
  }, [isSessionExpired]);

  return (
    <StompSessionProvider url={`${APIConfig.chatBaseURL}${APIConfig.chatWS}`}>
      <Box sx={styles.root}>
        <DrawerNavigator />
        <ContentLayout>
          {props.children}
        </ContentLayout>
      </Box>
    </StompSessionProvider>
  );
}

export default Main;
