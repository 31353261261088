import { ActualSessionStatus } from '../../../../utils/constants';
import Dimens from '../../../../theme/dimens';
import { Props } from '.';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the ChatHeader component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (props: Props) => {

  const { sm, lg, xl, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
		appBar: {
			zIndex: 0,
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      backgroundImage: 'none',
      boxShadow: 'none',
			borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      p: section,
			position: 'relative',
			'& .MuiToolbar-root': {
				p: 0,
        minHeight: 'unset'
			},
      [theme.breakpoints.down('sm')]: {
        p: `${lg} ${xl}`
      }
    },
		avatar: {
      width: Dimens.avatarSize.xl,
      height: Dimens.avatarSize.xl,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
      cursor: 'pointer'
    },
		titleWrapper: {
			flexDirection: 'column',
			mx: section,
			flex: 1,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        mx: lg
      }
		},
		title: {
      ...commonStyles.textEllipsis(1),
			fontWeight: Dimens.fontWeight.bold
		},
		subTitle: {
			overflow: 'hidden',
			textOverflow: 'auto',
			whiteSpace: 'nowrap',
			color: () => {
        let color = theme.palette.warning.main;
        if (props.isGroup) {
          color = theme.palette.text.secondary;
        } else if (props.status === ActualSessionStatus.Online) {
          color = theme.palette.success.main;
        }
    
        return color;
      }
		},
		iconBtn: {
      p: sm,
      radius: Dimens.radius.md
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.md
    }
  };

  return styles;
};

export default useStyles;