import AppError from '../../exception/app-error';

export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';
export const FETCH_DOMAIN_LIST = 'FETCH_DOMAIN_LIST';
export const DOMAIN_LIST_SUCCESS = 'DOMAIN_LIST_SUCCESS';
export const ADD_DOMAIN = 'ADD_DOMAIN';
export const REMOVE_DOMAIN = 'REMOVE_DOMAIN';
export const DOMAIN_FAILURE = 'DOMAIN_FAILURE';
export const RESET_DOMAIN_STATE = 'RESET_DOMAIN_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface FetchDomainListAction {
  type: typeof FETCH_DOMAIN_LIST;
}

export interface DomainListSuccessAction {
  type: typeof DOMAIN_LIST_SUCCESS;
  payload: Array<string>;
}

export interface AddDomainAction {
  type: typeof ADD_DOMAIN;
}

export interface RemoveDomainAction {
  type: typeof REMOVE_DOMAIN;
}

export interface DomainFailureAction {
  type: typeof DOMAIN_FAILURE;
  payload: AppError;
}

export interface DomainStateResetAction {
  type: typeof RESET_DOMAIN_STATE;
}

export type DomainActionTypes = ResetErrorStateAction | FetchDomainListAction | DomainListSuccessAction | AddDomainAction
 | RemoveDomainAction | DomainFailureAction | DomainStateResetAction;