import { ActualSessionStatus, SessionType } from '../../../../../utils/constants';
import {
  Divider,
  Drawer,
  List
} from '@mui/material';

import APP_NAV from '../../../../../routes/app-nav';
import BellIcon from '../../../../../components/CustomIcons/BellIcon';
import Box from '@mui/material/Box/Box';
import DrawerItemData from '../../../../../types/ui/drawer-item-data';
import LeaveIcon from '../../../../../components/CustomIcons/LeaveIcon';
import ParentDrawerItem from './ParentDrawerItem';
import React from 'react';
import SettingsIcon from '../../../../../components/CustomIcons/SettingsIcon';
import { useInboxApi } from '../../../../../data/inbox/api';
import { useLocation } from 'react-router-dom';
import { useSignInApi } from '../../../../../data/sign-in/api';
import useStyles from './styles';

/**
 * Interface defining the props expected by the ParentDrawer component.
 */
interface Props {
  topMenuList: Array<DrawerItemData>;
  onDrawerItemClick: (item: DrawerItemData) => void;
}

/**
 * ParentDrawer Component
 * 
 * This component renders the top-level navigation drawer with menu items.
 * It utilizes the provided `topMenuList` and `onDrawerItemClick` props
 * for customization and user interaction.
 * 
 * @param {Props} props - An object containing required props for the component.
 * @returns {JSX.Element} - The JSX element representing the parent drawer.
 */
const ParentDrawer: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const location = useLocation();
  const inboxApi = useInboxApi();
  const signInApi = useSignInApi();

  // Navigation menu items for the bottom section
  const drawerBottomMenuList: Array<DrawerItemData> = [
    {
      icon: <BellIcon />,
      hasBadge: true,
      route: APP_NAV.NOTIFICATIONS,
      hide: true
    },
    {
      icon: <SettingsIcon />,
      route: APP_NAV.SETTINGS
    }
  ];

  /**
   * Function to check if the provided route matches the current pathname.
   * Takes into account removing the leading slash for proper comparison.
   * 
   * @param {string} route - The route path to check.
   * @returns {boolean} - True if the route matches the current pathname (excluding leading slash), false otherwise.
   */
  const isActiveRoute = (route: string): boolean => {
    
    return location.pathname !== '/' && `/${location.pathname.split('/')[1]}` === route;
  };

  /**
   * Handles the logout functionality.
   * 
   * Calls the `performSignOut` method from the `signInApi`.
   * The `isSessionTimeout` argument (boolean) indicates whether the logout is triggered due to a session timeout or
   * a manual user action. On logout clear the chat session and reset the global inbox state.
   * 
   */
  const handleLogOut = () => {
    inboxApi.updateChatSessionStatus(ActualSessionStatus.Offline);
    signInApi.performSignOut(false);
  }

  return (
    <Drawer variant='permanent' sx={styles.drawer}>
      <List sx={styles.list}>
        {props.topMenuList.map((item, index) => (
          <ParentDrawerItem
            key={`drawer_top_${index}`}
            {...item}
            isActive={isActiveRoute(item.route)}
            onClick={() => props.onDrawerItemClick(item)}
          />
        ))}
      </List>
      <Box sx={styles.footer}>
        <List sx={styles.list}>
          {drawerBottomMenuList.map((item, index) => (
            <ParentDrawerItem
              key={`drawer_bottom_${index}`}
              {...item}
              isActive={isActiveRoute(item.route)}
              onClick={() => props.onDrawerItemClick(item)}
            />
          ))}
        </List>
        <Divider sx={styles.divider} />
        <List sx={styles.list}>
          <ParentDrawerItem icon={<LeaveIcon />} isDestructive={true} onClick={handleLogOut} />
        </List>
      </Box>
    </Drawer>
  );
};

export default ParentDrawer;
