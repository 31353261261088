import { Box } from '@mui/material';
import CustomButton from '../../../../components/CustomButton';
import MenuList from '../../SettingsMenuList';
import React from 'react';
import { SETTINGS_SECURITY_MENU_OPTIONS } from '../../../../utils/ui-constants';
import SettingsMenuData from '../../../../types/ui/settings_menu_data';
import { SettingsSubMenu } from '../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Renders a security settings submenu with various options
 * 
 * @returns {JSX.Element} JSX element representing the security settings submenu.
 */
const SecuritySubMenu: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	/**
	 * Handles click events for menu items.
	 * If the 'Change Password' option is clicked, navigation is triggered (to be implemented).
	 * 
	 * @param {SettingsMenuData} item - The selected menu item.
	 */
	const handleItemClick = (item: SettingsMenuData) => {
		if (item.id === SettingsSubMenu.ChangePassword) {
			//TODO: possible change in UI

		}
	};

	/**
	 * Handles the click event for the "Deactivate My Account" button.
	 * Placeholder for the deactivation logic (to be implemented).
	 */
	const handleDeactive = () => {
		// TODO: Implement account deactivation logic

	};

	return (
		<Box sx={styles.container}>
			<MenuList
				menuItems={SETTINGS_SECURITY_MENU_OPTIONS}
				onItemClick={handleItemClick}
			/>
			{/* // Hide deactivate button until handleded in background
			<CustomButton 
			type='submit' 
			title={t('deactivateMyAccount')} 
			color='secondary' 
			fullWidth 
			onClick={handleDeactive} 
			destructive /> */}
		</Box>
	);
};

export default SecuritySubMenu;
