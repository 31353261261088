import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DotIcon = (props: SvgIconProps) => {

	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
				<path d="M0 8.5C-1.56548e-08 9.55058 0.206926 10.5909 0.608964 11.5615C1.011 12.5321 1.60028 13.414 2.34315 14.1569C3.08601 14.8997 3.96793 15.489 4.93853 15.891C5.90914 16.2931 6.94943 16.5 8 16.5C9.05058 16.5 10.0909 16.2931 11.0615 15.891C12.0321 15.489 12.914 14.8997 13.6569 14.1569C14.3997 13.414 14.989 12.5321 15.391 11.5615C15.7931 10.5909 16 9.55058 16 8.5C16 7.44943 15.7931 6.40914 15.391 5.43853C14.989 4.46793 14.3997 3.58601 13.6569 2.84315C12.914 2.10028 12.0321 1.511 11.0615 1.10896C10.0909 0.706926 9.05058 0.5 8 0.5C6.94943 0.5 5.90914 0.706926 4.93853 1.10896C3.96793 1.511 3.08601 2.10028 2.34315 2.84315C1.60028 3.58601 1.011 4.46793 0.608964 5.43853C0.206926 6.40914 -1.56548e-08 7.44943 0 8.5Z" fill='currentColor' />
			</svg>
		</SvgIcon>
	);
};

export default DotIcon;