import {
	API_FAILURE,
	FETCH_POSITION_LIST,
	FETCH_USER_DETAIL,
	FETCH_USER_LIST,
	INVITE_USERS,
	INVITE_USERS_SUCCESS,
	POSITION_LIST_SUCCESS,
	RESET_ERROR_STATE,
	RESET_USER_STATE,
	SEARCH_USER_LIST,
	SEARCH_USER_LIST_SUCCESS,
	UPDATE_USER_DETAIL,
	USER_LIST_SUCCESS,
	UserActionTypes,
} from './action-types';

import UserState from '../../types/states/user-state';

/**
 * Initial state for the user reducer.
 */
export const userInitialState: UserState = {
	apiStatus: undefined,
	userList: [],
	searchedUserList: [],
	positionList: []
};

/**
 * User reducer function to handle state updates based on actions.
 *
 * @param {UserState} state The current user state.
 * @param {UserActionTypes} action The action to be handled.
 * 
 * @returns {UserState} The updated user state.
 */
const userReducer = (state: UserState, action: UserActionTypes): UserState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case SEARCH_USER_LIST:
		case FETCH_USER_LIST:
		case FETCH_USER_DETAIL:
		case UPDATE_USER_DETAIL:
		case FETCH_POSITION_LIST:
		case INVITE_USERS:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case USER_LIST_SUCCESS:
			return {
				...state,
				userList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case SEARCH_USER_LIST_SUCCESS:
			return {
				...state,
				searchedUserList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case POSITION_LIST_SUCCESS:
			return {
				...state,
				positionList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case INVITE_USERS_SUCCESS:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: true,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_USER_STATE:
			return {
				...userInitialState
			};
		default:
			return state;
	}
}

export default userReducer;