import {
	ADD_DOMAIN,
	AddDomainAction,
	DOMAIN_FAILURE,
	DOMAIN_LIST_SUCCESS,
	DomainFailureAction,
	DomainListSuccessAction,
	DomainStateResetAction,
	FETCH_DOMAIN_LIST,
	FetchDomainListAction,
	REMOVE_DOMAIN,
	RESET_DOMAIN_STATE,
	RESET_ERROR_STATE,
	RemoveDomainAction,
	ResetErrorStateAction
} from './action-types';

import AppError from '../../exception/app-error';

/**
 * Reset the domain error state.
 *
 * @returns {ResetErrorStateAction} An action to reset the domain error state.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Initiates fetching the domain list.
 *
 * @returns {FetchDomainListAction} An action to indicate fetching the domain list.
 */
export const initDomainListFetch = (): FetchDomainListAction => ({
	type: FETCH_DOMAIN_LIST
});

/**
 * Dispatched on successful retrieval of domain list.
 *
 * @param {Array<string>} domainList - The list of fetched domains.
 * @returns {DomainListSuccessAction} An action to store the retrieved domain list.
 */
export const domainListSuccess = (domainList: Array<string>): DomainListSuccessAction => ({
	type: DOMAIN_LIST_SUCCESS,
	payload: domainList
});

/**
 * Initiates creating a new domain.
 *
 * @returns {AddDomainAction} An action to indicate creating a new domain.
 */
export const initDomainAdd = (): AddDomainAction => ({
	type: ADD_DOMAIN
});

/**
 * Initiates deleting a domain.
 *
 * @returns {RemoveDomainAction} An action to indicate deleting a domain.
 */
export const initDomainRemove = (): RemoveDomainAction => ({
	type: REMOVE_DOMAIN
});

/**
 * Dispatched on domain data fetching/creation/update/deletion failure.
 *
 * @param {AppError} error - The error object.
 * @returns {DomainFailureAction} An action to indicate domain data operation failure.
 */
export const domainFailure = (error: AppError): DomainFailureAction => ({
	type: DOMAIN_FAILURE,
	payload: error
});

/**
 * Resets the domain state to its initial state.
 *
 * @returns {DomainStateResetAction} An action to reset the domain state.
 */
export const resetDomainState = (): DomainStateResetAction => ({
	type: RESET_DOMAIN_STATE
});