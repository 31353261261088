import {
	API_FAILURE,
	APP_FEATURE_LIST_SUCCESS,
	ApiFailureAction,
	AppFeatureListSuccessAction,
	CREATE_USER_TYPE,
	CreateUserTypeAction,
	DELETE_USER_TYPE,
	DeleteUserTypeAction,
	FETCH_APP_FEATURE_LIST,
	FETCH_USER_TYPE_LIST,
	FetchAppFeatureListAction,
	FetchUserTypeListAction,
	RESET_USER_TYPE_STATE,
	UPDATE_USER_TYPE,
	USER_TYPE_LIST_SUCCESS,
	UpdateUserTypeAction,
	UserTypeListSuccessAction,
	UserTypeStateResetAction,
} from './action-types';

import AppError from '../../exception/app-error';
import Permission from '../../types/permission';
import UserType from '../../types/user-type';

/**
 * Action creator to fetch the user type list.
 *
 * @returns {FetchUserTypeListAction} - The action to fetch the user type list.
 */
export const initUserTypeListFetch = (): FetchUserTypeListAction => ({
	type: FETCH_USER_TYPE_LIST
});

/**
 * Initializes the fetch app feature list action.
 *
 * @returns {FetchAppFeatureListAction} The fetch app feature list action object.
 */
export const initAppFeatureListFetch = (): FetchAppFeatureListAction => ({
  type: FETCH_APP_FEATURE_LIST
});

/**
 * Initializes the create user type action.
 *
 * @returns {CreateUserTypeAction} The create user type action object.
 */
export const initUserTypeCreate = (): CreateUserTypeAction => ({
  type: CREATE_USER_TYPE
});

/**
 * Initializes the update user type action.
 *
 * @returns {UpdateUserTypeAction} The update user type action object.
 */
export const initUserTypeUpdate = (): UpdateUserTypeAction => ({
  type: UPDATE_USER_TYPE
});

export const initUserTypeDelete = (): DeleteUserTypeAction => ({
  type: DELETE_USER_TYPE
});

/**
 * Action creator to indicate successful user type list fetching.
 *
 * @param {Array<UserType>} userTypeList - The fetched userType list.
 * 
 * @returns {UserTypeListSuccessAction} - The action to indicate successful fetching with the user type list as payload.
 */
export const userTypeListSuccess = (userTypeList: Array<UserType>): UserTypeListSuccessAction => ({
	type: USER_TYPE_LIST_SUCCESS,
	payload: userTypeList
});

/**
 * Creates an action to indicate successful retrieval of app feature list.
 *
 * @param {Array<Permission>} featureList The list of app features.
 * 
 * @returns {AppFeatureListSuccessAction} The action object.
 */
export const appFeatureListSuccess = (featureList: Array<Permission>): AppFeatureListSuccessAction => ({
	type: APP_FEATURE_LIST_SUCCESS,
	payload: featureList
});

/**
 * Action creator to handle API failure.
 *
 * @param {AppError} error - The API error object.
 * 
 * @returns {ApiFailureAction} - The action to indicate API failure with the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error,
});

/**
 * Resets the user-type state to its initial state.
 *
 * @returns {UserTypeStateResetAction} An action to reset the user-type state.
 */
export const resetUserTypeState = (): UserTypeStateResetAction => ({
	type: RESET_USER_TYPE_STATE
});

