import { Box, Table, TableBody, TableContainer, useMediaQuery, useTheme } from '@mui/material';
import { DEFAULT_PAGE_SIZE, DeviceListSubMenu, DeviceSortBy, SortByAlphabet, SortOrder } from '../../utils/constants';
import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '../../components/CustomIcons/AddIcon';
import CircularProgressBar from '../../components/CircularProgressBar';
import CreatePagerDialog from './CreatePagerDialog';
import DeviceEditPanel from './DeviceEditPanel';
import DeviceListTableHeader from './DeviceListTableHeader';
import DeviceListTableRow from './DeviceListTableRow';
import DeviceViewPanel from './DeviceViewPanel';
import { FETCH_PAGER_LIST } from '../../data/device/action-types';
import InlineAlert from '../../components/InlineAlert';
import MenuData from '../../types/ui/menu-data';
import NoDataFound from '../../components/NoDataFound';
import { Pager } from '../../types/pager';
import { PagerListRequest } from '../../types/pager-list-request';
import SearchAndSortTopBar from '../../components/SearchAndSortTopBar';
import Util from '../../utils/util';
import { getDeviceTableHeaders } from '../../utils/ui-constants';
import { useDeviceApi } from '../../data/device/api';
import { useLoadMore } from '../../hooks/use-load-more';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Device Screen Component
 *
 * This component displays a screen for managing a list of pagers. It allows users to search, sort,
 * filter by columns, and perform actions on individual pagers.
 */
const DeviceScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isLoadMore = useLoadMore(containerRef);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<SortByAlphabet>(SortByAlphabet.A_Z);
  const [searchKey, setSearchKey] = useState<string>('');
  const [columns, setColumns] = useState<Array<MenuData>>(getDeviceTableHeaders(isDesktop));
  const [pagerListRequest, setPagerListRequest] = useState<PagerListRequest>();
  const [openCreatePagerDialog, setOpenCreatePagerDialog] = useState<boolean>(false);
  const [selectedPager, setSelectedPager] = useState<Pager>();
  const [openDeviceViewPanel, setOpenDeviceViewPanel] = useState<boolean>(false);
  const [openDeviceEditPanel, setOpenDeviceEditPanel] = useState<boolean>(false);
  const deviceApi = useDeviceApi();
  const apiStatus = deviceApi.state.device.apiStatus;

  /**
   * Fetches the pager type list and initializes the initial pager list request on component mount.
   */
  useEffect(() => {
    deviceApi.fetchPagerTypeList();
    initPagerListFetchRequest(searchKey, sortBy);
  }, []);

  /**
   * Fetches additional pagers when the user scrolls to the bottom of the list.
   */
  useEffect(() => {
    if (isLoadMore && !Util.isApiLoading([FETCH_PAGER_LIST], apiStatus)
      && pagerListRequest && !pagerListRequest.isPaginationComplete) {
      pagerListRequest.page += 1;
      initPagerListFetchApi(pagerListRequest);
    }
  }, [isLoadMore]);

  /**
   * Handles changes in column visibility.
   *
   * @param {MenuData} menu - The menu item representing the column.
   */
  const onColumnVisibilityChange = (menu: MenuData) => {
    const updatedColumns = [...columns];
    updatedColumns.map(column => {
      if (column.id === menu.id) {
        column.isChecked = menu.isChecked;
      }
    });
    setColumns(updatedColumns);
  }

  /**
   * Handles the click event on a pager item.
   *
   * @param {Pager} pager - The selected pager object.
   */
  const onItemSelected = (pager: Pager) => {
    setSelectedPager(pager);
    setOpenDeviceViewPanel(true);
  }

  /**
   * Handles closing the device view panel and resetting the selected pager.
   */
  const handleDeviceViewPanelClose = () => {
    setSelectedPager(undefined);
    setOpenDeviceViewPanel(false);
  }

  /**
   * Handles closing the device edit panel and resetting the selected pager.
   */
  const handleDeviceEditPanelClose = () => {
    setSelectedPager(undefined);
    setOpenDeviceEditPanel(false);
  }

  /**
   * Handles the click event on a sub-menu item within a pager row.
   *
   * @param {Pager} pager - The pager object.
   * @param {MenuData} menu - The sub-menu item clicked.
   */
  const onSubMenuItemSelected = (pager: Pager, menu: MenuData) => {
    setSelectedPager(pager);
    if (menu.id === DeviceListSubMenu.Edit) {
      setOpenDeviceEditPanel(true);
    } else if (menu.id === DeviceListSubMenu.Delete) {
      // TODO:
    }
  }

  /**
   * Filters and sorts the pager list based on search and sort criteria.
   *
   * @returns {Array<Pager>} The filtered and sorted pager list.
   */
  const getFilteredPagerList = (): Array<Pager> => {
    let filteredPagerList: Array<Pager> = deviceApi.state.device.pagerList;
    if (pagerListRequest && pagerListRequest.isFetchedAll) {
      filteredPagerList = filteredPagerList.filter((pager) =>
        pager.name.toLowerCase().includes(searchKey.toLowerCase()) ||
        pager.destination.toString().includes(searchKey.toLowerCase()));
    }
    filteredPagerList.sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);

      return sortBy === SortByAlphabet.A_Z ? comparison : -comparison;
    });

    return filteredPagerList;
  }

  const filteredPagerList = getFilteredPagerList();

  /**
   * Handles changes in sort order.
   *
   * @param {SortByAlphabet} sortBy - The new sort order.
   */
  const onSortChange = (sortBy: SortByAlphabet) => {

    setSortBy(sortBy);
    if (pagerListRequest && !pagerListRequest.isFetchedAll) {
      initPagerListFetchRequest(searchKey, sortBy);
    }
  }

  /**
   * Handles changes in the search keyword.
   *
   * @param {string} searchKey - The new search keyword.
   */
  const onSearchKeyChange = (searchKey: string) => {

    setSearchKey(searchKey);
    if (pagerListRequest && !pagerListRequest.isFetchedAll) {
      initPagerListFetchRequest(searchKey, sortBy);
    }
  }

  /**
   * Initializes a new pager list request object with search and sort criteria.
   *
   * @param {string} searchKey - The search keyword.
   * @param {SortByAlphabet} sortBy - The sort order (ascending/descending).
   */
  const initPagerListFetchRequest = (searchKey: string, sortBy: SortByAlphabet) => {
    const request: PagerListRequest = {
      query: searchKey,
      page: 0,
      size: DEFAULT_PAGE_SIZE,
      sortBy: DeviceSortBy.Name,
      order: sortBy === SortByAlphabet.A_Z ? SortOrder.Asc : SortOrder.Desc,
      isPaginationComplete: false
    };
    initPagerListFetchApi(request);
  }

  /**
   * Fetches a paginated list of pagers based on the provided request object.
   * Updates the pager list state and sets pagination completion flag.
   *
   * @param {PagerListRequest} request - The pager list request object.
   */
  const initPagerListFetchApi = (request: PagerListRequest) => {
    deviceApi.fetchPagerList(request).then((data) => {
      request.isPaginationComplete = (data?.length ?? 0) < DEFAULT_PAGE_SIZE;
      if (!request.query) {
        request.isFetchedAll = request.isPaginationComplete;
      }
      setPagerListRequest(request);
    });
  }

  return (
    <Box sx={styles.container}>
      <SearchAndSortTopBar
        title={t('devices')}
        subTitle={t('manageYourDevices')}
        actionText={t('add')}
        actionIcon={<AddIcon />}
        onActionClick={() => setOpenCreatePagerDialog(true)}
        sortBy={sortBy}
        onSortChange={onSortChange}
        onSearchChange={onSearchKeyChange}
      />
      <Box sx={styles.content}>
        <InlineAlert message={Util.getApiError([FETCH_PAGER_LIST], apiStatus)} />
        {Util.isArrayEmpty(filteredPagerList) ? (
          <NoDataFound message={t('noDevicesFound')} />
        ) : (
          <TableContainer sx={styles.tableContainer} ref={containerRef}>
            <Table stickyHeader>
              <DeviceListTableHeader
                columns={columns}
                onColumnVisibilityChange={onColumnVisibilityChange}
              />
              <TableBody component={'tbody'}>
                {filteredPagerList.map((pager, index) => (
                  <DeviceListTableRow
                    key={`user-${index}`}
                    pager={pager}
                    columns={columns}
                    onClick={() => onItemSelected(pager)}
                    handleSubMenuItemClick={onSubMenuItemSelected}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {openCreatePagerDialog &&
        <CreatePagerDialog open={openCreatePagerDialog} onClose={() => setOpenCreatePagerDialog(false)} />
      }
      <DeviceViewPanel
        open={openDeviceViewPanel}
        pager={selectedPager}
        onClose={handleDeviceViewPanelClose}
      />
				<DeviceEditPanel
					open={openDeviceEditPanel}
					pager={selectedPager}
					onClose={handleDeviceEditPanelClose}
				/>
      <CircularProgressBar show={Util.isApiLoading([FETCH_PAGER_LIST], apiStatus)} />
    </Box>
  );
};

export default DeviceScreen;
