import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material';

import React from 'react';
import SettingsMenuData from '../../../types/ui/settings_menu_data';
import theme from '../../../theme/theme';
import useStyles from './styles';

interface MenuListProps {
	menuItems: SettingsMenuData[];
	onItemClick: (item: SettingsMenuData) => void;
	onToggleChange?: (item: SettingsMenuData) => void;
}

/**
 * Renders a list of menu items for a settings page.
 *
 * @param {MenuListProps} props - Component props:
 *   - menuItems: Array of menu items to display, following the `SettingsMenuData` interface.
 *   - onItemClick: Callback function triggered when a menu item is clicked, receiving the clicked item as an argument.
 *   - onToggleChange (optional): Callback function triggered when a toggle switch is changed.
 *
 * @returns {JSX.Element} JSX element representing the rendered list of menu items.
 */
const SettingsMenuList: React.FC<MenuListProps> = (props: MenuListProps) => {

	const { menuItems, onItemClick, onToggleChange, ...rest } = props;
	const styles = useStyles();

	return (
		<Box sx={styles.container}>
			<List {...rest} sx={styles.listContainer}>
				{menuItems.flatMap((option, index) => [
					<ListItem
						key={`menu-${index}-${option.id}`}
						value={option.id}
						sx={styles.menuItem}
						onClick={() => onItemClick(option)}>
						<ListItemText>
							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<Typography variant={'p1'}>{option.value}</Typography>
								{option.description && (
									<Typography variant={'p1'} sx={{ color: theme.palette.text.secondary }}>
										{option.description}
									</Typography>
								)}
							</Box>
						</ListItemText>
						<ListItemIcon sx={styles.iconWrapper}>
							{option.hasToggle ? (
								<Switch
									edge='end'
									checked={Boolean(option.toggleValue)}
									onChange={() => {
										if (onToggleChange) {
											onToggleChange(option);
										}
									}}
								/>
							) : (
								option.icon
							)}
						</ListItemIcon>
					</ListItem>,
					index !== menuItems.length - 1 && <Divider key={`divider-${index}`} sx={styles.divider} />
				])}
			</List>
		</Box>
	);
};

export default SettingsMenuList;
