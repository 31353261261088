import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import AlertTriangleIcon from '../../../components/CustomIcons/AlertTriangleIcon';
import AutocompleteChipInput from '../../../components/AutocompleteChipInput';
import ChevronRightIcon from '../../../components/CustomIcons/ChevronRightIcon';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInputSelect from '../../../components/CustomInputSelect';
import { INVITE_USERS } from '../../../data/user/action-types';
import InlineAlert from '../../../components/InlineAlert';
import InviteIcon from '../../../components/CustomIcons/InviteIcon';
import InviteUserRequest from '../../../types/invite-user-request';
import InviteUserRequestData from '../../../types/ui/invite-user-request-data';
import MenuData from '../../../types/ui/menu-data';
import { REGEX_PATTERN_EMAIL } from '../../../utils/constants';
import Util from '../../../utils/util';
import { useDomainApi } from '../../../data/domain/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props extends DialogProps {
	onClose: (success: boolean) => void;
}

/**
 * Renders a dialog for inviting users to the system.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} JSX element representing the UserInviteDialog component.
 */
const UserInviteDialog: React.FC<Props> = (props: Props) => {

	const { onClose, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [showInitialForm, setShowInitialForm] = useState<boolean>(true);
	const inviteRequest: InviteUserRequestData = {
		userType: '',
		department: undefined,
		emails: []
	};
	const domainApi = useDomainApi();
	const userApi = useUserApi();
	const apiStatus = userApi.state.user.apiStatus;
	const userTypeList: Array<MenuData> = userApi.state.userType.userTypeList.map(userType => ({
		id: userType.userType,
		value: userType.userType
	}));

	// Map the domain list from the domain API state, prefixing each domain with '@' 
	// to match email format (for comparison later).
	const domainList = domainApi.state.domain.domainList.map(domain => `@${domain}`);

	/**
	 * Counts how many emails in the provided array do not belong to the organization's domain list.
	 * 
	 * @param {Array<string>} emails - An array of email addresses to check.
	 * 
	 * @returns {number} - The count of emails that are outside the organization's domain.
	 */
	const getEmailCountOutsideOrg = (emails: Array<string>): number => {
		let count = 0;
		emails.forEach(email => {
			const isOutsideOrg = !Util.isArrayEmpty(domainList) && !domainList.some(domain => email.endsWith(domain));
			if (isOutsideOrg) {
				count++;
			}
		});

		return count;
	};

	/**
	 * Generates a warning message if there are emails that do not belong to the organization's domain.
	 * 
	 * @param {Array<string>} emails - An array of email addresses to check.
	 * 
	 * @returns {string} - A localized warning message if any emails are outside the organization's domain,
	 * otherwise an empty string.
	 */
	const getWarningMessage = (emails: Array<string>): string => {
		let message = '';
		const count = getEmailCountOutsideOrg(emails);
		if (count > 0) {
			message = count > 1 ? `${count} ${t('users')}` : `1 ${t('user')}`;
			message = `${message} ${t('domainOutsideWarning')}`
		}

		return message;
	}

	/**
	 * Handles the form submission for the user invite dialog.
	 *
	 * @param {InviteUserRequestData} values - The form values containing user type, department, and email addresses.
	 */
	const onSubmit = (values: InviteUserRequestData) => {
		if (showInitialForm) {
			setShowInitialForm(false);
		} else {
			const inviteRequest: InviteUserRequest = {
				userType: values.userType,
				departmentId: values.department?.id,
				invitations: values.emails.map(email => ({
					loginId: email
				}))
			};
			userApi.inviteUsers(inviteRequest).then(data => {
				if (data) {
					onClose(true);
				}
			});
		}
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={() => onClose(false)}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>{t('inviteCollegues')}</Typography>
				<IconButton onClick={() => onClose(false)}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([INVITE_USERS], apiStatus)} />
			<Formik
				enableReinitialize
				initialValues={inviteRequest}
				onSubmit={() => {/*Do Nothing*/ }}>
				{({ dirty, values }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							{showInitialForm ? (
								<>
									<Field
										name='userType'
										label={t('userType')}
										placeholder={t('choosePreset')}
										tooltip={t('userTypeTooltip')}
										menu={userTypeList}
										component={CustomInputSelect}
										hasBottomSpacing
									/>
									<Field
										name='department'
										label={t('department')}
										subLabel={t('optional')}
										placeholder={t('findDepartments')}
										component={CustomAutocomplete}
										noOptionsText={t('noDepts')}
										menu={userApi.state.department.departmentList}
									/>
								</>
							) : (
								<>
									<Field
										name='emails'
										label={t('emails')}
										placeholder={t('emailAddress')}
										regExp={REGEX_PATTERN_EMAIL}
										component={AutocompleteChipInput}
										whiteList={domainList}
									/>
									{getEmailCountOutsideOrg(values.emails) > 0 &&
										<Box sx={styles.warningLayout}>
											<AlertTriangleIcon />
											<Typography variant='footer'>{getWarningMessage(values.emails)}</Typography>
										</Box>
										}
								</>
							)}
						</DialogContent>
						<DialogActions>
							<CustomButton
								title={showInitialForm ? t('next') : t('send')}
								color='primary'
								disabled={!dirty || (showInitialForm ? !values.userType : Util.isArrayEmpty(values.emails))}
								endIcon={showInitialForm ? <ChevronRightIcon /> : <InviteIcon />}
								onClick={() => onSubmit(values)}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([INVITE_USERS], apiStatus)} />
		</Dialog>
	);
};

export default UserInviteDialog;