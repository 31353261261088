import {
	API_FAILURE,
	FETCH_PROFILE,
	PROFILE_SUCCESS,
	ProfileActionTypes,
	RESET_ERROR_STATE,
	RESET_PROFILE_STATE,
	UPDATE_PROFILE,
	UPDATE_PROFILE_IMAGE,
} from './action-types';

import LoginUtil from '../../utils/login-util';
import ProfileState from '../../types/states/profile-state';

/**
 * Initial state for the profile reducer.
 */
export const profileInitialState: ProfileState = {
	apiStatus: undefined,
	user: {
		name: LoginUtil.getUserFullName(),
		position: '',
		type: '',
		email: LoginUtil.getLoginId(),
		defaultDepartmentId: LoginUtil.getDefaultDeptId(),
		pager: undefined
	},
};

/**
 * Profile reducer function to handle state updates based on actions.
 *
 * @param {ProfileState} state The current user state.
 * @param {ProfileActionTypes} action The action to be handled.
 * 
 * @returns {ProfileState} The updated user state.
 */
const profileReducer = (state: ProfileState, action: ProfileActionTypes): ProfileState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined,
			};
		case FETCH_PROFILE:
		case UPDATE_PROFILE:
		case UPDATE_PROFILE_IMAGE:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false,
				},
			};
		case PROFILE_SUCCESS:
			return {
				...state,
				user: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true,
				},
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true,
				},
			};
		case RESET_PROFILE_STATE:
			return {
				...profileInitialState
			};
		default:
			return state;
	}
};


export default profileReducer;