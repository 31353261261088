import {
	ADD_DOMAIN,
	DOMAIN_FAILURE,
	DOMAIN_LIST_SUCCESS,
	DomainActionTypes,
	FETCH_DOMAIN_LIST,
	REMOVE_DOMAIN,
	RESET_DOMAIN_STATE,
	RESET_ERROR_STATE
} from './action-types';

import DomainState from '../../types/states/domain-state';

export const domainInitialState: DomainState = {
	apiStatus: undefined,
	domainList: []
};

/**
 * Domain Reducer function to handle state updates based on dispatched actions.
 *
 * @param {DomainState} state - The current domain state.
 * @param {DomainActionTypes} action - The dispatched action object.
 * @returns {DomainState} - The updated domain state.
 */
const domainReducer = (state: DomainState, action: DomainActionTypes): DomainState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case FETCH_DOMAIN_LIST:
		case ADD_DOMAIN:
		case REMOVE_DOMAIN:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case DOMAIN_LIST_SUCCESS:
			return {
				...state,
				domainList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case DOMAIN_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_DOMAIN_STATE:
			return {
				...domainInitialState
			};
		default:
			return state;
	}
}

export default domainReducer;