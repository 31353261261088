import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import CustomButton from '../CustomButton';
import Dimens from '../../theme/dimens';
import React, { } from 'react';
import useStyles from './styles';

/**
 * Interface defining props for the TopBar component.
 * 
 * @interface TopBarProps
 */
export interface TopBarProps {
  /**
   * The main title to be displayed in the TopBar.
   */
  title: string;
  /**
   * Optional subtitle to be displayed below the title.
   */
  subTitle?: string;
  /**
   * Boolean prop to control whether to display a divider line below the content.
   */
  hasDivider?: boolean;
  /**
   * Text for an optional action button displayed on the right side.
   */
  actionText?: string;
  /**
   * Optional JSX element to be used as the icon for the end action button.
   */
  actionIcon?: JSX.Element;

  onActionClick?: () => void;
  /**
   * Optional JSX element to be used as the icon for the start action button.
   */
  startActionIcon?: JSX.Element;
  /**
   * Boolean prop to control whether an optional icon button displayed on the right side.
   */
  hasIconButton?: boolean;
  /**
   * Optional JSX element to be used for the end icon button.
   */
  iconButtonIcon?: JSX.Element;

}

/**
 * TopBar component for displaying titles, subtitles, and optional action buttons.
 * 
 * This component allows for customization of the top bar through props. It accepts a title,
 * an optional subtitle, a divider option, action button text, and an optional action icon.
 * It renders a styled container with the title, subtitle (if provided), and an optional 
 * action button on the right side.
 *
 * @param {TopBarProps} props - Component properties defining title, subtitle, and action options.
 *
 * @returns {JSX.Element} - The rendered TopBar component.
 */
const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

  const styles = useStyles(props.hasDivider);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={styles.container}>
      <Stack sx={styles.titleWrapper}>
        <Typography variant={isMobile ? 'h3' : 'h2'} fontWeight={Dimens.fontWeight.bold}>
          {props.title}
        </Typography>
        {props.subTitle &&
          <Typography variant='p2' sx={styles.subTitle}>
            {props.subTitle}
          </Typography>}
      </Stack>
      {props.actionText &&
        <Box>
          <CustomButton
            color='primary'
            title={props.actionText}
            endIcon={props.actionIcon || undefined}
            startIcon={props.startActionIcon || undefined}
            onClick={props.onActionClick}
          />
        </Box>
      }
      {(props.hasIconButton && props.iconButtonIcon) &&
        <Box>
          <IconButton
            onClick={props.onActionClick}>
            {props.iconButtonIcon}
          </IconButton>
        </Box>
      }
    </Box>
  );
};

export default TopBar;
