import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DeviceTab component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { lg, section } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      p: section,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    }
  };

  return styles;
};

export default useStyles;