import { Box, Chip } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';

import APP_NAV from '../../routes/app-nav';
import AddIcon from '../../components/CustomIcons/AddIcon';
import { AppFeature } from '../../utils/constants';
import CircularProgressBar from '../../components/CircularProgressBar';
import CrossIcon from '../../components/CustomIcons/CrossIcon';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import { FETCH_DOMAIN_LIST } from '../../data/domain/action-types';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import TopBar from '../../components/TopBar';
import Util from '../../utils/util';
import { domainSchema } from '../../utils/validation-schema';
import { useDomainApi } from '../../data/domain/api';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface DomainData {
  domain: string;
}

/**
 * Domain Screen Component
 *
 * This component renders the domain management screen. It allows users to add new approved
 * email domains and displays a list of existing ones which will used for white listing emails.
 */
const DomainScreen: React.FC = () => {

  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialValue: DomainData = {
    domain: ''
  };
  const domainApi = useDomainApi();
  const apiStatus = domainApi.state.domain.apiStatus;

  /**
   * Initializes the domain fetch if the user has the necessary permission.
   *
   * This effect checks if the logged-in user has the `ManageSystemSetting` permission.
   * If yes, it initiates the domain fetch. If not, it navigates to the appropriate
   * route based on the user's permissions (determined by `Util.getNavigationByPermission()`).
   */
  useEffect(() => {
    if (LoginUtil.hasPermission(AppFeature.ManageSystemSetting)) {
      domainApi.fetchDomainList();
    } else {
      navigate(Util.getNavigationByPermission());
    }
  }, []);

  /**
   * Checks if a specific domain already exists in the domain list.
   *
   * @param {string} domain - The domain name to check.
   * @returns {boolean} True if the domain exists, false otherwise.
   */
  const isDomainExist = (domain: string) => {

    return domainApi.state.domain.domainList.includes(domain);
  }

  /**
   * Handles the deletion of a domain from the list.
   *
   * @param {string} option - The domain name to be deleted.
   */
  const onChipDelete = (option: string) => {
    domainApi.deleteDomain(option);
  }

  /**
   * Handles the form submission for adding a new domain.
   *
   * @param {DomainData} values - The submitted domain data.
   * @param {FormikHelpers<DomainData>} formikHelpers - Formik helper functions for form manipulation.
   */
  const onSubmit = (values: DomainData, formikHelpers: FormikHelpers<DomainData>) => {
    domainApi.addDomain(values.domain).then(data => {
      if (data) {
        formikHelpers.resetForm();
      }
    });
  }

  return (
    <Box sx={styles.container}>
      <TopBar title={t('domain')} subTitle={t('domainMsg')} hasDivider />
      <Box sx={styles.content}>
        <InlineAlert message={apiStatus?.error} />
        <Formik
          initialValues={initialValue}
          validationSchema={domainSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, values }) => (
            <Form style={styles.form as React.CSSProperties}>
              <Field
                name='domain'
                label={t('approvedEmailDomains')}
                component={CustomInput}
              />
              <Box sx={styles.btnWrapper}>
                <CustomButton
                  type='submit'
                  title={t('add')}
                  color='primary'
                  startIcon={<AddIcon />}
                  disabled={!dirty || !isValid || isDomainExist(values.domain)}
                />
              </Box>
            </Form>
          )}
        </Formik>
        <Box sx={styles.chipWrapper}>
          {domainApi.state.domain.domainList.map((option, index) => (
            <Chip
              variant='filled'
              sx={styles.chip}
              key={`chip-index-${index}`}
              label={`@${option}`}
              onDelete={() => onChipDelete(option)}
              deleteIcon={<CrossIcon />}
            />
          ))}
        </Box>
      </Box>
      <CircularProgressBar show={Util.isApiLoading([FETCH_DOMAIN_LIST], apiStatus)} />
    </Box>
  );
};

export default DomainScreen;
