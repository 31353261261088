import axios, { AxiosRequestConfig, ResponseType } from 'axios';

import APIConfig from './api-config';
import { AppErrorCode } from '../exception/exception-constants';
import AxiosErrorHandler from '../exception/axios-error-handler';
import LoginUtil from '../utils/login-util';
import SignUpUtil from '../utils/sign-up-util';

/**
 * Helper function to configure Axios request based on endpoint and authentication status.
 * - Sets default headers (Content-Type, Cache-Control).
 * - Includes access token in Authorization header if required.
 *
 * @param {string} url - The API endpoint URL.
 * @param {boolean} isMultipart - The API endpoint URL.
 * 
 * @returns {AxiosRequestConfig<any>} Configured Axios request object.
 */
export const axiosConfig = (url: string, isMultipart?: boolean):  AxiosRequestConfig<any> => { /* eslint-disable-line */
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': isMultipart ? 'multipart/form-data; boundary=----test' : 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache'
    }
  }
  let accessToken;
  if (url === APIConfig.refreshToken) {
    accessToken = LoginUtil.getRefreshTokenData()?.token
  } else {
    accessToken = LoginUtil.isLoggedIn() ? LoginUtil.getAccessTokenData()?.token
      : SignUpUtil.getAccessTokenData()?.token;
  }
  if (accessToken) {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${accessToken}`
    }
  }

  return config;
}

/**
 * Helper function to construct the full API endpoint URL.
 * - Checks if the provided endpoint already includes the base URL.
 * - Prepends base URL and relative path from APIConfig if needed.
 *
 * @param {string} endPoint - The API endpoint path.
 * @returns {string} A promise resolving to the full API endpoint URL.
 */
export const getUrl = (endPoint: string) => {
  const orgDomain: string = LoginUtil.isLoggedIn() ? LoginUtil.getOrgDomain() : SignUpUtil.getOrgDomain();
  let url = `${APIConfig.baseURL}${APIConfig.relPath}${endPoint}`;
  if (endPoint.startsWith(APIConfig.baseURL)) {
    url = endPoint;
  } else if (orgDomain && !endPoint.startsWith(`/${orgDomain}`)) {
    url = `${APIConfig.baseURL}${APIConfig.relPath}/${orgDomain}${endPoint}`;
  }

  return url;
}

/**
 * Sends a POST request to the specified API endpoint.
 * - Handles access token retrieval and configuration.
 * - Uses AbortSignal for cancellation (optional).
 * - Catches and handles Axios errors using AxiosErrorHandler.
 *
 * @param {string} endPoint - The API endpoint path.
 * @param {any} payload - The request payload data (optional).
 * @param {AbortSignal} signal - An optional AbortSignal for cancellation.
 * @returns {Promise<any>} A promise resolving to the API response data.
 * @throws {AppError} If the request fails (including cancellation).
 */
export const doPost = async (endPoint: string, payload?: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.post(url, payload, { ...configs, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

/**
 * Sends a GET request to the specified API endpoint.
 * - Handles access token retrieval and configuration.
 * - Uses AbortSignal for cancellation (optional).
 * - Catches and handles Axios errors using AxiosErrorHandler.
 *
 * @param {string} endPoint - The API endpoint path.
 * @param {AbortSignal} signal - An optional AbortSignal for cancellation.
 * @returns {Promise<any>} A promise resolving to the API response data.
 * @throws {AppError} If the request fails (including cancellation).
 */
export const doGet = async (endPoint: string, responseType?: ResponseType, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.get(url, { ...configs, signal: abortSignal, responseType: responseType ?? 'json'});
    
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

/**
 * Sends a PUT request to the specified API endpoint.
 * - Handles access token retrieval and configuration.
 * - Uses AbortSignal for cancellation (optional).
 * - Catches and handles Axios errors using AxiosErrorHandler.
 *
 * @param {string} endPoint - The API endpoint path.
 * @param {any} payload - The request payload data (optional).
 * @param {AbortSignal} signal - An optional AbortSignal for cancellation.
 * @returns {Promise<any>} A promise resolving to the API response data.
 * @throws {AppError} If the request fails (including cancellation).
 */
export const doPut = async (endPoint: string, payload?: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.put(url, payload, { ...configs, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

/**
 * Sends a DELETE request to the specified API endpoint.
 * - Handles access token retrieval and configuration.
 * - Uses AbortSignal for cancellation (optional).
 * - Catches and handles Axios errors using AxiosErrorHandler.
 *
 * @param {string} endPoint - The API endpoint path.
 * @param {AbortSignal} signal - An optional AbortSignal for cancellation.
 * @returns {Promise<any>} A promise resolving to the API response data.
 * @throws {AppError} If the request fails (including cancellation).
 */
export const doDelete = async (endPoint: string, payload?: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.delete(url, { ...configs, data: payload, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

/**
 * Sends a PATCH request to the specified API endpoint.
 * - Handles access token retrieval and configuration.
 * - Uses AbortSignal for cancellation (optional).
 * - Catches and handles Axios errors using AxiosErrorHandler.
 *
 * @param {string} endPoint - The API endpoint path.
 * @param {any} payload - The request payload data (optional).
 * @param {AbortSignal} signal - An optional AbortSignal for cancellation.
 * @returns {Promise<any>} A promise resolving to the API response data.
 * @throws {AppError} If the request fails (including cancellation).
 */
export const doPatch = async (endPoint: string, payload?: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.patch(url, payload, { ...configs, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

export const doMultipartPost = async (endPoint: string, payload: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint, true);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.post(url, payload, { ...configs, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};

export const doMultipartPut = async (endPoint: string, payload: any, signal?: AbortSignal): Promise<any> => { /* eslint-disable-line */
  const configs = axiosConfig(endPoint, true);
  const url = getUrl(endPoint);

  // Create a new cancellation controller if no signal is provided
  const controller = signal ? undefined : new AbortController();
  const abortSignal = controller?.signal;

  try {
    const response = await axios.put(url, payload, { ...configs, signal: abortSignal });
    return response.data;
  } catch (error: any) { /* eslint-disable-line */
    const appError = AxiosErrorHandler.handleError(error);
    if (appError.code === AppErrorCode.ABORT_ERROR) { //AbortError
      // Handle cancellation gracefully (optional)
    } else {
      throw appError;
    }
  } finally {
    controller?.abort(); // Release resources if a new controller was created
  }
};
