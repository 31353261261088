import AppError from '../../exception/app-error';
import Department from '../../types/department';

export const FETCH_DEPT_LIST = 'FETCH_DEPT_LIST';
export const FETCH_DEPT_DETAIL = 'FETCH_DEPT_DETAIL';
export const CREATE_DEPT = 'CREATE_DEPT';
export const UPDATE_DEPT = 'UPDATE_DEPT';
export const DELETE_DEPT = 'DELETE_DEPT';
export const DEPT_LIST_SUCCESS = 'DEPT_LIST_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const RESET_DEPT_STATE = 'RESET_DEPT_STATE';

export interface FetchDeptListAction {
	type: typeof FETCH_DEPT_LIST;
}

export interface CreateDeptAction {
	type: typeof CREATE_DEPT;
}

export interface UpdateDeptAction {
	type: typeof UPDATE_DEPT;
}

export interface FetchDeptDetailAction {
	type: typeof FETCH_DEPT_DETAIL;
}

export interface DeleteDeptAction {
	type: typeof DELETE_DEPT;
}

export interface DeptListSuccessAction {
	type: typeof DEPT_LIST_SUCCESS;
	payload: Array<Department>;
}

export interface ApiFailureAction {
	type: typeof API_FAILURE;
	payload: AppError;
}

export interface DeptStateResetAction {
  type: typeof RESET_DEPT_STATE;
}

export type DeptActionTypes = FetchDeptListAction | CreateDeptAction | UpdateDeptAction | DeptListSuccessAction
	| DeleteDeptAction | FetchDeptDetailAction | ApiFailureAction | DeptStateResetAction;
