import {
  Box,
  Fab,
  IconButton,
  Typography,
} from '@mui/material';
import { IMAGE_EXT_JPEG, IMAGE_MIME_TYPE, VIDEO_EXT_MP4, VIDEO_MIME_TYPE } from '../../utils/constants';
import React, { useCallback, useRef, useState } from 'react';

import CrossIcon from '../CustomIcons/CrossIcon';
import Util from '../../utils/util';
import Webcam from 'react-webcam';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onCaptureComplete: (file: File) => void;
  onClose: () => void;
}

/**
 * Renders a component for capturing media (image or video) using webcam.
 *
 * @param {Props} props - Component props containing callbacks for capture completion and closure.
 * 
 * @returns {JSX.Element} JSX element representing the WebCamMediaCapture component.
 */
const WebCamMediaCapture: React.FC<Props> = (props: Props) => {

  const [capturing, setCapturing] = useState<boolean>(false);
  const styles = useStyles(capturing);
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isImage, setIsImage] = useState<boolean>(true);
  const [recordedChunks, setRecordedChunks] = useState([]);

  /**
   * Handles media type selection (image or video).
   *
   * @param {boolean} isImage - Flag indicating whether to capture image or video.
   */
  const onMediaTypeClick = (isImage: boolean): void => {
    setIsImage(isImage);
    if (isImage) {
      setCapturing(false);
      setRecordedChunks([]);
    }
  }

  /**
   * Captures an image using the webcam.
   * (Uses useCallback for performance optimization)
   */
  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const fileName = `image_${new Date().getTime().toString()}${IMAGE_EXT_JPEG}`;
        const blob = Util.convertBase64ToBlob(imageSrc);
        const file = Util.convertBlobToFile(blob, fileName);
        props.onCaptureComplete(file);
      }
    }
  }, [webcamRef]);

  /**
   * Handles data available event from the MediaRecorder for video capture.
   * (Uses useCallback for performance optimization)
   *
   * @param {MediaRecorderEvent} response - Event object containing recorded data.
   */
  const handleDataAvailable = useCallback((response: any) => { /* eslint-disable-line */
    const { data } = response;
    if (data.size > 0) {
      const updatedChunk = recordedChunks.concat(data);
      setRecordedChunks(updatedChunk);
      const blob = new Blob(updatedChunk, {
        type: VIDEO_MIME_TYPE
      });
      const fileName = `video_${new Date().getTime().toString()}${VIDEO_EXT_MP4}`;
      const file = Util.convertBlobToFile(blob, fileName);
      props.onCaptureComplete(file);
    }
  }, [setRecordedChunks]);

  /**
   * Starts video recording using MediaRecorder.
   * (Uses useCallback for performance optimization)
   */
  const startVideo = useCallback(() => {
    if (webcamRef.current && webcamRef.current.stream) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: VIDEO_MIME_TYPE,
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.start();
    }
  }, []);

  /**
   * Handles video capture start/stop.
   */
  const captureVideo = () => {
    if (capturing) {
      mediaRecorderRef?.current?.stop();
    } else {
      startVideo();
    }
    setCapturing(!capturing);
  }

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.closeBtn} onClick={props.onClose}>
        <CrossIcon />
      </IconButton>
      <Webcam
        audio={true}
        mirrored={true}
        height={'100%'}
        ref={webcamRef}
        screenshotFormat={IMAGE_MIME_TYPE}
        audioConstraints={{
          echoCancellation: true,
          autoGainControl: false,
          noiseSuppression: true
        }}
      />
      <Box sx={styles.footerLayout}>
        <Box sx={styles.mediaTypeLayout}>
          <Typography
            variant='p1'
            color={isImage ? 'unset' : 'primary'}
            onClick={() => onMediaTypeClick(false)}
          >
            {t('video')}
          </Typography>
          <Typography
            variant='p1'
            color={isImage ? 'primary' : 'unset'}
            onClick={() => onMediaTypeClick(true)}
          >
            {t('photo')}
          </Typography>
        </Box>
        <Fab onClick={() => isImage ? captureImage() : captureVideo()}>
          {!isImage && <Box sx={styles.recordBtn} />}
        </Fab>
      </Box>
    </Box>
  );
};

export default WebCamMediaCapture;
